import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FiltersContainer, TextInput } from '@epcbuilder/lib/components';
import { Filters } from '@/models/properties';

const FiltersForm = ({
  register,
  errors,
  reset,
}: {
  register: UseFormRegister<Filters>;
  errors: FieldErrors<Filters>;
  reset: () => void;
}) => {
  return (
    <FiltersContainer id="admins-filters" reset={reset}>
      <div className="w-full sm:w-96 sm:self-end">
        <TextInput
          {...register('search')}
          id="search"
          name="search"
          title="your search query"
          placeholder="Search"
          error={errors.search?.message}
        />
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;
