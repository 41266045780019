import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFilterList } from 'react-icons/md';
import { Accordion, Button, IconButton, Loading } from '@epcbuilder/lib/components';
import { User } from '@epcbuilder/lib/models/user';
import useAdmins from '@/hooks/users/useAdmins';
import { Filters } from '@/models/properties';
import AdminsHeader from './AdminsHeader';
import AdminsRow from './AdminsRow';
import CreateNewAdminModal from './CreateNewAdminModal';
import FiltersForm from './Filters';

const AdminsTable = () => {
  const defaultValues = {
    search: '',
  };

  const {
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm<Filters>({ defaultValues });
  const search = watch('search');
  const { admins, mutate, isLoading } = useAdmins();

  const [filters, setFilters] = useState<boolean>(false);
  const [showCreateAdminModal, setShowCreateAdminModal] = useState<boolean>(false);
  const [filteredAdmins, setFilteredAdmins] = useState<User[] | undefined>(undefined);

  useEffect(() => {
    setFilteredAdmins(
      admins?.filter(
        (admin) =>
          admin.firstName.toLowerCase().includes(search.toLowerCase()) ||
          admin.lastName.toLowerCase().includes(search.toLowerCase()) ||
          admin.email.toLowerCase().includes(search.toLowerCase()) ||
          `${admin.firstName.toLowerCase()} ${admin.lastName.toLowerCase()}`.includes(search.toLowerCase())
      )
    );
  }, [admins, search]);

  return (
    <>
      <Accordion
        accordionOpen={filters}
        accordionChildren={<FiltersForm register={register} errors={errors} reset={() => reset(defaultValues)} />}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <h1 className="text-2xl">Admins</h1>
          <div className="flex flex-1 flex-row items-center justify-end gap-4">
            <IconButton id="filters-button" style="secondary" onClick={() => setFilters(!filters)}>
              <MdFilterList size={20} />
            </IconButton>
            <div className="w-36">
              <Button id="add-new-button" onClick={() => setShowCreateAdminModal(true)}>
                Add new
              </Button>
            </div>
          </div>
        </div>
      </Accordion>
      <AdminsHeader />
      {isLoading || !filteredAdmins ? (
        <Loading id="admins-loading" />
      ) : filteredAdmins.length === 0 ? (
        <p id="admins-count-empty" className="my-8 flex items-center justify-center">
          There are no admins that match these filters
        </p>
      ) : (
        <div id="admins-table">
          {filteredAdmins && filteredAdmins.map((admin) => <AdminsRow key={admin.id} admin={admin} mutate={mutate} />)}
        </div>
      )}
      {showCreateAdminModal && (
        <CreateNewAdminModal
          onClose={() => {
            setShowCreateAdminModal(false);
            mutate();
          }}
        />
      )}
    </>
  );
};

export default AdminsTable;
