import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';
import useMediaQuery from '@epcbuilder/lib/hooks/useMediaQuery';
import { Absence } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';
import { addDays, addMonths, format, isAfter, isBefore, isSameDay } from 'date-fns';
import BookingSlotCalendar from './BookingSlotCalendar';

const AssessmentCalendar = ({
  id,
  onAbsenceSelect,
  onSlotClick,
  onBookingSelect,
}: {
  id: string;
  onAbsenceSelect: (absence: Absence) => void;
  onSlotClick: (date: Date, slot: number) => void;
  onBookingSelect: (booking: Booking | undefined) => void;
}) => {
  const isMobile = useMediaQuery(1023);
  const DAYS_TO_SHOW = useMemo(() => (isMobile ? 3 : 5), [isMobile]);
  const FIRST_DATE = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }, []);
  const LAST_DATE = useMemo(() => addMonths(FIRST_DATE, 3), [FIRST_DATE]);
  const [start, setStart] = useState(FIRST_DATE);
  const [end, setEnd] = useState(FIRST_DATE);

  useEffect(() => {
    setStart(FIRST_DATE);
    setEnd(FIRST_DATE);
  }, [FIRST_DATE]);

  const generateDates = (start: Date, daysToShow: number) => {
    const dates = [];
    let currentDate = start;
    while (dates.length < daysToShow) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        dates.push(new Date(currentDate));
      }
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  const dates = useMemo(() => {
    return generateDates(start, DAYS_TO_SHOW);
  }, [start, DAYS_TO_SHOW]);

  const updateDates = useCallback(
    (newStart: Date) => {
      setStart(newStart);
      const newEndDates = generateDates(newStart, DAYS_TO_SHOW);
      setEnd(newEndDates[newEndDates.length - 1]);
    },
    [DAYS_TO_SHOW]
  );

  const handleForwards = useCallback(() => {
    const nextStart = addDays(start, 7);
    updateDates(nextStart);
  }, [start, updateDates]);

  const handleBackwards = useCallback(() => {
    const previousStart = addDays(start, -7);
    if (isBefore(previousStart, FIRST_DATE)) {
      updateDates(FIRST_DATE);
    } else {
      updateDates(previousStart);
    }
  }, [start, updateDates, FIRST_DATE]);

  const handleAbsenceSelect = (absence: Absence) => {
    onAbsenceSelect(absence);
  };

  const handleSlotSelect = (date: Date, slot: number) => {
    onSlotClick(date, slot);
  };

  const handleBookingSelect = (booking: Booking | undefined) => {
    if (booking) {
      onBookingSelect(booking);
    } else {
      onBookingSelect(undefined);
    }
  };

  return (
    <Card className="relative mt-8 justify-start lg:max-w-none">
      <div className="flex min-w-[294px] flex-col">
        <div className="bg-blue dark:bg-primary w-full rounded-[12px]">
          <div className="relative flex flex-row items-center justify-center p-2">
            <button
              type="button"
              className="absolute left-2 flex h-full items-center justify-center"
              onClick={handleBackwards}
              disabled={isSameDay(start, FIRST_DATE) || isBefore(start, FIRST_DATE)}
            >
              <MdKeyboardArrowLeft
                id="left-button"
                size={22}
                className={`${
                  (isSameDay(start, FIRST_DATE) || isBefore(start, FIRST_DATE)) && 'text-blue dark:text-primary'
                }`}
              />
            </button>
            <div className="flex flex-row items-center gap-4">
              {dates.map((date, index) => {
                const dayFormatted = format(date, 'EEE');
                const dateFormatted = format(date, 'd/M');

                return (
                  <div key={index} className="flex w-[6rem] flex-col items-center">
                    <p id={`${dayFormatted}-dayName`} className="font-bold">
                      {dayFormatted}
                    </p>
                    <p id={`${dateFormatted}-date`} className="font-bold">
                      {dateFormatted}
                    </p>
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="absolute right-2 flex h-full items-center justify-center"
              onClick={handleForwards}
              disabled={isSameDay(end, LAST_DATE) || isAfter(end, LAST_DATE)}
            >
              <MdKeyboardArrowRight
                id="right-button"
                size={22}
                className={`${(isSameDay(end, LAST_DATE) || isAfter(end, LAST_DATE)) && 'text-blue dark:text-primary'}`}
              />
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-2 px-2">
          <div className="border-blue dark:border-primary-dark h-full border-r-[2px]" />
          {dates.map((date) => (
            <BookingSlotCalendar
              id={id}
              key={date.toString()}
              date={date}
              onAbsenceClick={handleAbsenceSelect}
              onSlotClick={handleSlotSelect}
              onBookingSelect={handleBookingSelect}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default AssessmentCalendar;
