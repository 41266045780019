import { Login } from '@epcbuilder/lib/models/auth';
import { api } from '@epcbuilder/lib/utils';

export const postLogin = async (data: Login) => {
  return await api.post('/auth/login', data);
};

export const postRefreshToken = async (data: { refreshToken: string }) => {
  return await api.post('/auth/refresh-token', data);
};
