import React from 'react';
import { Helmet } from 'react-helmet';
import { FullPage } from '@epcbuilder/lib/components';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta name="description" content="" />
      </Helmet>
      <FullPage>
        <p>404 - not found</p>
      </FullPage>
    </>
  );
};

export default NotFound;
