import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { Select } from '@epcbuilder/lib/components';

const absenceTypeOptions = [
  { value: '1', label: 'Holiday' },
  { value: '2', label: 'Sickness' },
  { value: '3', label: 'Other' },
  { value: '4', label: 'Bank Holiday' },
];

const SelectAbsenceType = <T extends FieldValues>({ control, error }: { control: Control<T>; error?: string }) => {
  return (
    <div id="absence-type-dropdown" className="flex flex-1 flex-col flex-wrap gap-2 sm:flex-row sm:items-center">
      <div className="w-full">
        <Select
          className="border-primary-lighter bg-color-white hover:border-primary-lighter focus:border-primary-lighter placeholder:text-color flex h-12 flex-1 flex-row items-center justify-center rounded-[24px] border-2 px-4 shadow outline-none"
          control={control}
          id="absenceTypeId"
          name={'absenceTypeId' as Path<T>}
          title="Absence absenceTypeId"
          options={absenceTypeOptions}
        />
      </div>
      {error && (
        <div id="absence-type-error" className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};
export default SelectAbsenceType;
