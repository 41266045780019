import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdFilterList } from 'react-icons/md';
import { Accordion, IconButton, Loading } from '@epcbuilder/lib/components';
import { User } from '@epcbuilder/lib/models/user';
import useUsers from '@/hooks/users/useUsers';
import { Filters } from '@/models/properties';
import FiltersForm from './Filters';
import UsersHeader from './UsersHeader';
import UsersRow from './UsersRow';

const UsersTable = () => {
  const defaultValues = {
    search: '',
    showDeleted: false,
  };

  const {
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm<Filters>({ defaultValues });
  const search = watch('search');
  const showDeleted = watch('showDeleted');
  const { users, mutate, isLoading } = useUsers();

  const [filters, setFilters] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<User[] | undefined>(undefined);

  useEffect(() => {
    setFilteredUsers(
      users?.filter(
        (user) =>
          (user.firstName.toLowerCase().includes(search.toLowerCase()) ||
            user.lastName.toLowerCase().includes(search.toLowerCase()) ||
            user.email.toLowerCase().includes(search.toLowerCase()) ||
            `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(search.toLowerCase())) &&
          user.isDeleted === showDeleted
      )
    );
  }, [users, showDeleted, search]);

  return (
    <>
      <Accordion
        accordionOpen={filters}
        accordionChildren={<FiltersForm register={register} errors={errors} reset={() => reset(defaultValues)} />}
      >
        <div className="flex w-full flex-row items-center justify-between">
          <h1 className="text-2xl">Users</h1>
          <div className="flex flex-1 flex-row items-center justify-end gap-4">
            <IconButton id="filters-button" style="secondary" onClick={() => setFilters(!filters)}>
              <MdFilterList size={20} />
            </IconButton>
          </div>
        </div>
      </Accordion>
      <UsersHeader />
      {isLoading || !filteredUsers ? (
        <Loading id="users-loading" />
      ) : filteredUsers.length === 0 ? (
        <p id="users-empty" className="my-8 flex items-center justify-center">
          There are no users that match these filters
        </p>
      ) : (
        <div id="users-table">
          {filteredUsers && filteredUsers.map((user) => <UsersRow key={user.id} user={user} mutate={mutate} />)}
        </div>
      )}
    </>
  );
};

export default UsersTable;
