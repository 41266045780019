import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdClose, MdOutlineFileDownloadDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Loading, TextInput } from '@epcbuilder/lib/components';
import { Assessor, AssessorFormInput } from '@epcbuilder/lib/models/assessors';
import { EMAIL_REGEX, handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const assessorSchema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid first name')
    .required('First Name must not be empty'),
  lastName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid last name')
    .required('Last Name must not be empty'),
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  phoneNumber: yup
    .string()
    .matches(PHONE_REGEX, 'Phone is not a valid UK phone number')
    .required('Phone must not be empty'),
  officeAddress: yup.string().required('Office Address must not be empty'),
  officePostcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
});

const AssessorFormModal = ({
  defaultValues,
  onClose,
  onSubmit,
  title,
  successMessage,
}: {
  defaultValues: AssessorFormInput;
  onClose: () => void;
  onSubmit: SubmitHandler<AssessorFormInput>;
  title: string;
  successMessage?: string;
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<AssessorFormInput>({
    defaultValues,
    resolver: yupResolver(assessorSchema),
    reValidateMode: 'onSubmit',
  });

  const handleFormSubmit: SubmitHandler<AssessorFormInput> = async (data) => {
    try {
      await onSubmit(data);
      toast.success(`${successMessage}`, { toastId: `${title.toLowerCase().replace(' ', '-')}-success` });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<Assessor>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button
        type="button"
        className="bg-modal-blur fixed inset-0 flex items-center justify-center opacity-20"
        onClick={onClose}
      />
      <div
        id="assessor-form-modal"
        className="bg-modal border-primary relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:w-full sm:max-w-[400px] sm:rounded-xl"
      >
        <button
          type="button"
          className="text-primary-lighter absolute right-0 top-0 cursor-pointer p-4"
          onClick={onClose}
        >
          <MdClose size={24} />
        </button>
        <p className="flex justify-center font-bold">{title.toUpperCase()}</p>
        <form className="mt-4 flex flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
          <p className="font-bold">First Name</p>
          <TextInput
            {...register('firstName')}
            id="firstName"
            name="firstName"
            error={errors.firstName?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('firstName')}
          />
          <p className="mt-4 font-bold">Last Name</p>
          <TextInput
            {...register('lastName')}
            id="lastName"
            name="lastName"
            error={errors.lastName?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('lastName')}
          />
          <p className="mt-4 font-bold">E-mail</p>
          <TextInput
            {...register('email')}
            id="email"
            name="email"
            error={errors.email?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('email')}
          />
          <p className="mt-4 font-bold">Phone Number</p>
          <TextInput
            {...register('phoneNumber')}
            id="phoneNumber"
            name="phoneNumber"
            error={errors.phoneNumber?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('phoneNumber')}
          />
          <p className="mt-4 font-bold">Office Address</p>
          <TextInput
            {...register('officeAddress')}
            id="officeAddress"
            name="officeAddress"
            error={errors.officeAddress?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('officeAddress')}
          />
          <p className="mt-4 font-bold">Office Postcode</p>
          <TextInput
            {...register('officePostcode')}
            id="officePostcode"
            name="officePostcode"
            error={errors.officePostcode?.message}
            overrideBaseClassnames
            className="text-dark border-blue focus:border-primary-lighter h-10 w-full rounded-xl border-2 px-4 text-base outline-none"
            callbackOnChange={() => clearErrors('officePostcode')}
          />
          <div className="mt-4 flex justify-center">
            <button
              id="assessor-submit"
              className="bg-blue font-header flex h-12 min-w-fit items-center justify-center rounded-xl px-4 text-base drop-shadow-[0px_4px_0px_#9CCEC8]"
            >
              <p className="text-dark flex flex-row gap-4">
                <MdOutlineFileDownloadDone size={20} /> Save Changes
              </p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssessorFormModal;
