import { Absence } from '@epcbuilder/lib/models/assessors';

export const transformDefaultValues = (absence: Absence) => {
  const startDate = absence.absenceDateStart.toString().split('T')[0];
  const endDate = absence.absenceDateEnd.toString().split('T')[0];
  const startTime = absence.absenceDateStart.toString().split('T')[1];
  const endTime = absence.absenceDateEnd.toString().split('T')[1];

  const getSlot = (time: string): string => {
    const hour = parseInt(time.split(':')[0], 10);
    if (hour >= 0 && hour < 12) return 'am';
    if (hour >= 12 && hour < 24) return 'pm';
    return '';
  };

  return {
    id: absence.id,
    absenceDateStart: new Date(startDate),
    absenceDateEnd: new Date(endDate),
    slotStart: getSlot(startTime),
    slotEnd: getSlot(endTime),
    absenceTypeId: absence.absenceTypeId.toString(),
    notes: absence.notes || '',
  };
};
