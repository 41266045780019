import React, { MutableRefObject, useMemo } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';
import { Children } from '@epcbuilder/lib/utils';
import { format } from 'date-fns';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import { putReportSchemaEligible } from '@/network/report';
import { EpcGraph } from './report-epc-graph';
import { Bottom, Page, Top } from './ReportLayout';

const Rating = ({
  type,
  rating,
  children,
}: {
  type: 'current' | 'actual' | 'potential';
  rating?: string;
  children: Children;
}) => {
  return (
    <div className="bg-light-dark flex h-[236px] w-[160px] flex-col gap-2 rounded-[18px] p-2">
      <div className="flex flex-col text-center">
        <p className="text-xs font-black uppercase italic leading-none">{type}</p>
        <p className="text-xs font-bold uppercase leading-none">EPC Rating</p>
      </div>
      <EpcGraph propertyRating={rating} />
      <div className="bg-blue flex h-[57px] w-full flex-col items-center justify-center gap-1 rounded-[12px] p-2">
        {children}
      </div>
    </div>
  );
};

const Eligiblity = ({
  id,
  grant,
  description,
  qualify,
  check,
  refetchJobReport,
}: {
  id: string;
  grant: string;
  description: string;
  qualify: string;
  check?: boolean;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="bg-neutral-light h-[70px] w-[260px] rounded-l-[18px] px-4 py-2">
        <p className="text-xs font-bold">{grant}</p>
        <p className="text-[9px] font-bold leading-tight">{description}</p>
      </div>
      <button
        type="button"
        onClick={async () => {
          await putReportSchemaEligible({ id, eligible: !check });
          await refetchJobReport();
        }}
        className={`${
          check ? 'bg-primary/80' : 'bg-secondary-dark/80'
        } flex h-[70px] w-[45px] items-center justify-center`}
      >
        {check ? <MdCheck className="text-light-dark" size={32} /> : <MdClose className="text-light-dark" size={32} />}
      </button>
      <div className="bg-neutral-light flex h-[70px] w-[175px] items-center justify-center rounded-r-[17px] p-4">
        <p className="text-xs font-bold">{qualify}</p>
      </div>
    </div>
  );
};

const ReportPage6 = ({
  refs,
  jobReport,
  refetchJobReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  const bestEpc = useMemo<string | undefined>((): string | undefined => {
    if (!jobReport) return undefined;

    if (!jobReport.improvements || jobReport.improvements.length === 0) return undefined;

    let combinedRatings = jobReport.improvements.map((imp) => imp.newEpc?.toUpperCase());

    combinedRatings = combinedRatings.concat(jobReport.epcSummary?.actual?.rating.toUpperCase());
    combinedRatings = combinedRatings.concat(jobReport.epcSummary?.current?.rating?.toUpperCase());

    const filteredRatings = combinedRatings.filter((x) => x !== undefined);

    const topRating = filteredRatings.sort().at(0);

    return topRating;
  }, [jobReport]);

  return (
    <Page refs={refs} index={6}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">EPC Summary</h1>
      </Top>
      <div className="mx-auto mt-24 flex flex-col gap-3">
        <div className="flex flex-row gap-2">
          <Rating type="current" rating={jobReport?.epcSummary?.current?.rating}>
            <p className="text-xs font-medium">Your registered EPC</p>
            <div className="flex flex-col text-center">
              <p className="text-primary text-xs font-bold uppercase underline">Valid until</p>
              <p className="text-primary text-xs font-bold">
                {jobReport?.epcSummary?.current?.validUntil
                  ? format(new Date(jobReport?.epcSummary.current.validUntil), 'dd/MM/yyyy')
                  : 'Unknown'}
              </p>
            </div>
          </Rating>
          <Rating type="actual" rating={jobReport?.epcSummary?.actual?.rating}>
            <p className="text-xs font-medium">EPC After assessment</p>
            <div className="flex flex-col text-center">
              <p className="text-primary text-xs font-bold uppercase underline">Inspection date</p>
              <p className="text-primary text-xs font-bold">
                {jobReport?.epcSummary?.actual?.inspectionDate
                  ? format(new Date(jobReport?.epcSummary.actual.inspectionDate), 'dd/MM/yyyy')
                  : 'Unknown'}
              </p>
            </div>
          </Rating>
          <Rating type="potential" rating={bestEpc}>
            <p className="text-xs font-medium">EPC After RetroFit</p>
            <div className="via-primary mb-2 h-0.5 w-full bg-gradient-to-r from-transparent to-transparent" />
          </Rating>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-primary text-center font-sans text-2xl font-black uppercase">Grants & Funding Checker</h1>
          <div className="bg-blue mx-auto rounded-[16px] px-6 py-2">
            <p className="text-center text-sm">
              We have checked your eligibility for the following grants and funding:
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <div className="w-[255px]">
                <p className="text-center font-bold leading-none">Scheme</p>
              </div>
              <div className="w-[50px]">
                <p className="text-center font-bold leading-none">Eligible?</p>
              </div>
              <div className="w-[160px]">
                <p className="text-center font-bold leading-none">Details</p>
              </div>
            </div>
            {jobReport?.reportSchemes.map((scheme) => (
              <Eligiblity
                key={scheme.id}
                id={scheme.id}
                grant={scheme.scheme?.name}
                description={scheme.scheme?.description}
                qualify={scheme.eligible ? scheme.scheme.detailsIfEligible : scheme.scheme?.detailsIfNotEligible}
                check={scheme.eligible}
                refetchJobReport={refetchJobReport}
              />
            ))}
            <div className="flex flex-col gap-2 text-center">
              <p className="text-primary text-xs font-bold leading-none">Eligibility is subject to final checks</p>
            </div>
          </div>
        </div>
      </div>
      <Bottom title="EPC Summary, Grants & Funding Checker" />
    </Page>
  );
};

export default ReportPage6;
