import { RegisterAdmin } from '@epcbuilder/lib/models/auth';
import { api } from '@epcbuilder/lib/utils';
import { EditAdmin } from '@/models/properties';

export const getAdmins = async () => {
  return await api.get('/adminusers');
};

export const deleteAdmin = async ({ id }: { id: string }) => {
  return await api.delete(`/adminusers/${id}`);
};

export const postAdmin = async (data: RegisterAdmin) => {
  return await api.post('/adminusers', data);
};

export const putAdmin = async ({ id, data }: { id: string; data: EditAdmin }) => {
  return await api.put(`/adminusers/${id}`, data);
};

export const patchAdminPassword = async ({ id, password }: { id: string; password: string }) => {
  return await api.patch(`/adminusers/${id}/updatepassword`, { password });
};

export const getLoggedInUser = async () => {
  return await api.get(`/adminusers/auth-user`);
};
