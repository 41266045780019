import { User } from '@epcbuilder/lib/models/user';
import useSWR from 'swr';
import { getAdmins } from '@/network/admins';

const useAdmins = () => {
  const { data, error, isLoading, mutate } = useSWR<User[]>('admins-data', async () => await getAdmins(), {
    keepPreviousData: true,
  });

  return {
    admins: data,
    error,
    mutate,
    isLoading,
  };
};

export default useAdmins;
