import { ImprovementItem } from '@epcbuilder/lib/models/jobs';
import { api } from '@epcbuilder/lib/utils';

export const deleteImprovementItem = async ({ id, itemId }: { id?: string; itemId?: string }) => {
  return await api.delete(`/improvement/${id}/item/${itemId}`);
};

export const postImprovementItem = async ({ id, data }: { id?: string; data: ImprovementItem }) => {
  return await api.post(`/improvement/${id}/item`, data);
};

export const updateImprovementItem = async ({ id, data }: { id?: string; data: ImprovementItem }) => {
  return await api.put(`/improvement/item/${id}/update`, data);
};

export const putImprovementEpc = async ({ id, newEpc }: { id?: string; newEpc: string }) => {
  return await api.put(`/improvement/${id}/epc/${newEpc}`);
};
