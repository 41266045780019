import React from 'react';
import { Logo, ThemeToggle } from '@epcbuilder/lib/components';

const TopBar = () => {
  return (
    <div className="fixed left-0 top-0 z-20 w-screen drop-shadow-[0px_0px_10px_rgba(42,156,142,0.73)]">
      <div className="bg-color-white flex h-12 w-full flex-row items-center justify-between gap-4 px-4 sm:h-24">
        <div className="relative w-24 sm:w-48">
          <Logo type="color" className="self-end" href="/properties" />
        </div>
        <div className="hidden flex-row items-center justify-between gap-4 sm:flex">
          <ThemeToggle />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
