import React, { MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  putReportPropertyOverviewFloor,
  putReportPropertyOverviewFloorDescription,
  putReportPropertyOverviewRoof,
  putReportPropertyOverviewRoofDescription,
  putReportPropertyOverviewWalls,
  putReportPropertyOverviewWallsDescription,
  putReportPropertyOverviewWindows,
  putReportPropertyOverviewWindowsDescription,
} from '@/network/report';
import { PropertyOverviewImage, TextField } from './helpers';
import { Bottom, Page, Top } from './ReportLayout';

const ReportPage4 = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  return (
    <Page refs={refs} index={4}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Property Overview</h1>
        <div className="bg-primary/10 ml-2 rounded-[4px] px-2 py-1">
          <p className="text-lg font-bold">Fabric and Insulation</p>
        </div>
      </Top>
      <div className="mx-[64px] mt-[104px] flex flex-col gap-6">
        <div className="flex flex-1 flex-row gap-6">
          <div className="relative flex flex-1 flex-col">
            <div className="bg-light-dark flex h-8 w-20 items-center justify-center self-end rounded-t-[18px] shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[80px] before:top-[-35px] before:h-[67px] before:w-8 before:rounded-br-[18px] before:bg-transparent before:shadow-[0_20px_0_0_#F5FFFD] before:content-['']">
              <p className="pt-2 text-xl font-black uppercase">Roof</p>
            </div>
            <div className="bg-light-dark h-[120px] w-full rounded-br-[18px] rounded-tl-[18px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <TextField
                name="Roof"
                title="Property Overview Roof"
                placeholder="Roof details..."
                savingReport={savingReport}
                defaultValue={jobReport?.propertyOverview.fabricAndInsulation?.roof}
                callback={putReportPropertyOverviewRoofDescription}
                className="bg-neutral-light relative z-10 h-[104px] w-[209px] overflow-hidden rounded-[12px]"
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
              />
            </div>
            <div className="bg-light-dark h-40 w-36 rounded-b-[18px] px-2 pb-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[49px] before:top-[152px] before:h-[67px] before:w-8 before:rounded-tl-[18px] before:bg-transparent before:shadow-[0_-20px_0_0_#F5FFFD] before:content-['']">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Roof"
                image={jobReport?.propertyOverview.fabricAndInsulation?.roofimage}
                callback={putReportPropertyOverviewRoof}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative h-[150px] w-[127px] overflow-hidden rounded-[12px]"
              />
            </div>
          </div>
          <div className="relative flex flex-1 flex-col">
            <div className="bg-light-dark flex h-8 w-[130px] items-center justify-center rounded-t-[18px] shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[63px] before:top-[-35px] before:h-[67px] before:w-8 before:rounded-bl-[18px] before:bg-transparent before:shadow-[0_20px_0_0_#F5FFFD] before:content-['']">
              <p className="pt-2 text-xl font-black uppercase">Windows</p>
            </div>
            <div className="bg-light-dark h-[120px] w-full rounded-bl-[18px] rounded-tr-[18px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <TextField
                name="Windows"
                title="Property Overview Windows"
                placeholder="Window details..."
                savingReport={savingReport}
                defaultValue={jobReport?.propertyOverview.fabricAndInsulation?.windows}
                callback={putReportPropertyOverviewWindowsDescription}
                className="bg-neutral-light relative z-10 h-[104px] w-[209px] overflow-hidden rounded-[12px]"
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
              />
            </div>
            <div className="bg-light-dark h-40 w-36 self-end rounded-b-[18px] px-2 pb-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[144px] before:top-[152px] before:h-[67px] before:w-8 before:rounded-tr-[18px] before:bg-transparent before:shadow-[0_-20px_0_0_#F5FFFD] before:content-['']">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Windows"
                image={jobReport?.propertyOverview.fabricAndInsulation?.windowsImage}
                callback={putReportPropertyOverviewWindows}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative h-[150px] w-[127px] overflow-hidden rounded-[12px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-row gap-6">
          <div className="relative flex flex-1 flex-col">
            <div className="bg-light-dark h-40 w-36 rounded-t-[18px] px-2 pt-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[49px] before:top-[93px] before:h-[67px] before:w-8 before:rounded-bl-[18px] before:bg-transparent before:shadow-[0_20px_0_0_#F5FFFD] before:content-['']">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Walls"
                image={jobReport?.propertyOverview.fabricAndInsulation?.wallsImage}
                callback={putReportPropertyOverviewWalls}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative h-[150px] w-[127px] overflow-hidden rounded-[12px]"
              />
            </div>
            <div className="bg-light-dark h-[120px] w-full rounded-bl-[18px] rounded-tr-[18px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <TextField
                name="Walls"
                title="Property Overview Walls"
                placeholder="Wall details..."
                savingReport={savingReport}
                defaultValue={jobReport?.propertyOverview.fabricAndInsulation?.walls}
                callback={putReportPropertyOverviewWallsDescription}
                className="bg-neutral-light relative z-10 h-[104px] w-[209px] overflow-hidden rounded-[12px]"
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
              />
            </div>
            <div className="bg-light-dark flex h-8 w-24 items-center justify-center self-end rounded-b-[18px] shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[96px] before:top-[280px] before:h-[67px] before:w-8 before:rounded-tr-[18px] before:bg-transparent before:shadow-[0_-20px_0_0_#F5FFFD] before:content-['']">
              <p className="pb-1 text-xl font-black uppercase">Walls</p>
            </div>
          </div>
          <div className="relative flex flex-1 flex-col">
            <div className="bg-light-dark before:bg-tranparent h-40 w-36 self-end rounded-t-[18px] px-2 pt-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[144px] before:top-[93px] before:h-[67px] before:w-8 before:rounded-br-[18px] before:shadow-[0_20px_0_0_#F5FFFD] before:content-['']">
              <PropertyOverviewImage
                savingReport={savingReport}
                title="Floor"
                image={jobReport?.propertyOverview.fabricAndInsulation?.floorimage}
                callback={putReportPropertyOverviewFloor}
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
                className="bg-blue-light relative h-[150px] w-[127px] overflow-hidden rounded-[12px]"
              />
            </div>
            <div className="bg-light-dark h-[120px] w-full rounded-br-[18px] rounded-tl-[18px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
              <TextField
                name="Floor"
                title="Property Overview Floor"
                placeholder="Floor details..."
                savingReport={savingReport}
                defaultValue={jobReport?.propertyOverview.fabricAndInsulation?.floor}
                callback={putReportPropertyOverviewFloorDescription}
                className="bg-neutral-light relative z-10 h-[104px] w-[209px] overflow-hidden rounded-[12px]"
                jobReport={jobReport}
                refetchJobReport={refetchJobReport}
              />
            </div>
            <div className="bg-light-dark flex h-8 w-24 items-center justify-center rounded-b-[18px] shadow-[0_5px_5px_rgba(38,70,83,0.1)] before:absolute before:right-[97px] before:top-[280px] before:h-[67px] before:w-8 before:rounded-tl-[18px] before:bg-transparent before:shadow-[0_-20px_0_0_#F5FFFD] before:content-['']">
              <p className="pb-1 text-xl font-black uppercase">Floor</p>
            </div>
          </div>
        </div>
      </div>
      <Bottom title="Property Overview - Fabric and Insulation" />
    </Page>
  );
};

export default ReportPage4;
