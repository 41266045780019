import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, DatePicker, Loading, Modal, TextInput } from '@epcbuilder/lib/components';
import { PropertyRating } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PropertyRatingRequest } from '@/models/properties';
import { postPropertyRating, putPropertyRating } from '@/network/properties';

const ratingSchema = yup.object().shape({
  dateOfInspection: yup.date().required('Date of Inspection must not be empty'),
  validUntil: yup.date().required('Valid Until must not be empty'),
  lmkKey: yup.string().optional(),
  certificateNumber: yup.string().optional(),
  currentEnergyEfficiency: yup.number().required('Current Energy Efficiency must not be empty'),
  potentialEnergyEfficiency: yup.number().required('Potential Energy Efficiency must not be empty'),
  builtForm: yup.string().required('Built Form must not be empty'),
  constructionAgeBand: yup.string().required('Construction Age Band must not be empty'),
  wallDescription: yup.string().required('Wall Description must not be empty'),
  floorDescription: yup.string().required('Floor Description must not be empty'),
  propertyType: yup.string().required('Property Type must not be empty'),
  co2EmissionsCurrent: yup.string().required('Current Co2 Emissions must not be empty'),
  co2EmissionsPotential: yup.string().required('Potential Co2 Emissions must not be empty'),
});

const PropertyRatingModal = ({
  id,
  onClose,
  propertyRating,
}: {
  id: string;
  onClose: () => void;
  propertyRating: PropertyRating | undefined;
}) => {
  const defaultValues = {
    dateOfInspection: propertyRating?.ratingCreatedOn ? new Date(propertyRating?.ratingCreatedOn) : new Date(),
    validUntil: propertyRating?.certificateValidUntil ? new Date(propertyRating?.certificateValidUntil) : new Date(),
    lmkKey: propertyRating?.lmkKey || '',
    certificateNumber: propertyRating?.certificateNumber || '',
    currentEnergyEfficiency: propertyRating?.currentEnergyEfficiency || 0,
    potentialEnergyEfficiency: propertyRating?.potentialEnergyEfficiency || 0,
    builtForm: propertyRating?.builtForm || '',
    constructionAgeBand: propertyRating?.constructionAgeBand || '',
    wallDescription: propertyRating?.wallDescription || '',
    floorDescription: propertyRating?.floorDescription || '',
    propertyType: propertyRating?.propertyType || '',
    co2EmissionsCurrent: propertyRating?.co2EmissionsCurrent || '',
    co2EmissionsPotential: propertyRating?.co2EmissionsPotential || '',
  };

  const {
    control,
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PropertyRatingRequest>({
    defaultValues,
    resolver: yupResolver(ratingSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<PropertyRatingRequest> = async () => {
    try {
      if (propertyRating) {
        await putPropertyRating({ id, propertyRatingId: propertyRating.id, data: getValues() });
      } else {
        await postPropertyRating({ id, data: getValues() });
      }
      toast.success('Property Rating Updated', { toastId: 'rating-success' });
      onClose();
    } catch (error: unknown) {
      const { errors, detail } = error as AxiosErrorData;
      handleFormErrors<PropertyRatingRequest>(setError, errors);

      switch (detail) {
        case 'This property rating is already up to date.':
          toast.error('This property rating is already up to date.', { toastId: 'rating-error' });
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="rating-modal" onClose={onClose}>
      <h1>{propertyRating ? 'Edit Rating' : 'Create Rating'}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <DatePicker id="dateOfInspection" control={control} name="dateOfInspection" label="Date of Inspection" />
        <DatePicker id="validUntil" control={control} name="validUntil" label="Valid Until" />
        <TextInput id="lmkKey" {...register('lmkKey')} label="LMK Key" error={errors.lmkKey?.message} />
        <TextInput
          id="certificateNumber"
          data-testid="certificateNumberInput"
          {...register('certificateNumber')}
          label="Certificate Number"
          error={errors.certificateNumber?.message}
        />
        <TextInput
          id="currentEnergyEfficiency"
          data-testid="currentEnergyEfficiencyInput"
          {...register('currentEnergyEfficiency')}
          label="Current Energy Efficiency"
          error={errors.currentEnergyEfficiency?.message}
        />
        <TextInput
          id="potentialEnergyEfficiency"
          data-testid="potentialEnergyEfficiencyInput"
          {...register('potentialEnergyEfficiency')}
          label="Potential Energy Efficiency"
          error={errors.potentialEnergyEfficiency?.message}
        />
        <TextInput
          id="co2EmissionsCurrent"
          data-testid="co2EmissionsCurrentInput"
          {...register('co2EmissionsCurrent')}
          label="Current Co2 Emissions"
          error={errors.co2EmissionsCurrent?.message}
        />
        <TextInput
          id="co2EmissionsPotential"
          data-testid="co2EmissionsPotentialInput"
          {...register('co2EmissionsPotential')}
          label="Potential Co2 Emissions"
          error={errors.co2EmissionsPotential?.message}
        />
        <TextInput
          id="builtForm"
          data-testid="builtFormInput"
          {...register('builtForm')}
          label="Built Form"
          error={errors.builtForm?.message}
        />
        <TextInput
          id="constructionAgeBand"
          data-testid="constructionAgeBandInput"
          {...register('constructionAgeBand')}
          label="Construction Age Band"
          error={errors.constructionAgeBand?.message}
        />
        <TextInput
          id="wallDescription"
          data-testid="wallDescriptionInput"
          {...register('wallDescription')}
          label="Wall Description"
          error={errors.wallDescription?.message}
        />
        <TextInput
          id="floorDescription"
          data-testid="floorDescriptionInput"
          {...register('floorDescription')}
          label="Floor Description"
          error={errors.floorDescription?.message}
        />
        <TextInput
          id="propertyType"
          data-testid="propertyTypeInput"
          {...register('propertyType')}
          label="Property Type"
          error={errors.propertyType?.message}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="rating-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PropertyRatingModal;
