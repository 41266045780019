import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Button } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { Job, SurveyStatus } from '@epcbuilder/lib/models/jobs';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import useSurveyBooking from '@/hooks/surveys/useSurveyBooking';
import { SurveyInstallationStatuses } from '@/models/job';
import { postCreateJobSurvey } from '@/network/jobs';
import { deleteSurveyBooking } from '@/network/surveys';
import ClearRequestModal from './modals/ClearRequestModal';
import JobSurveyCompletionModal from './modals/JobSurveyCompletionModal';
import JobSurveyModal from './modals/JobSurveyModal';
import JobSurveyStatus from './JobSurveyStatus';

const JobSurveyData = ({
  job,
  refetchJob,
  surveyStatuses,
}: {
  job: Job | undefined;
  refetchJob: () => void;
  surveyStatuses: SurveyInstallationStatuses[];
}) => {
  const { jobSurvey, error: jobSurveyError, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { surveyBooking, mutate: refetchSurveyBooking } = useSurveyBooking({ id: jobSurvey?.id });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [surveyCompletedLock, setSurveyCompletedLock] = useState<boolean>(false);
  const [showClearRequestModal, setShowClearRequestModal] = useState<boolean>(false);

  useEffect(() => {
    if (job?.pasHubJobId) {
      refetchJobSurvey();
    }
  }, [job, refetchJobSurvey]);

  useEffect(() => {
    setSurveyCompletedLock(
      (jobSurvey?.surveyStatusID &&
        jobSurvey.surveyStatusID != SurveyStatus.SURVEY_CANCELLED &&
        jobSurvey.surveyStatusID >= SurveyStatus.SURVEY_COMPLETED) ??
        false
    );
  }, [jobSurvey]);

  const data = {
    'Survey Date': surveyBooking?.surveyDate ? format(new Date(surveyBooking?.surveyDate), 'dd/MM/yyyy') : '',
    'Requested Slot': surveyBooking?.bookingSlot === 1 ? 'AM' : surveyBooking?.bookingSlot === 2 ? 'PM' : '',
    Paid: surveyBooking?.paid !== undefined && surveyBooking?.paid !== null ? String(surveyBooking?.paid) : '',
    Assessor: surveyBooking?.assessorName || '',
    Notes: surveyBooking?.notes,
  };

  const reportButtonDisabled = useMemo(
    () =>
      (jobSurvey?.surveyStatusID && jobSurvey?.surveyStatusID < SurveyStatus.SURVEY_COMPLETED) ||
      jobSurvey?.surveyStatusID === undefined,
    [jobSurvey]
  );

  const showClearRequestButton = useMemo(
    () =>
      jobSurvey?.surveyStatusID === SurveyStatus.SURVEY_REQUESTED &&
      jobSurvey.requestedBookingDate &&
      jobSurvey.requestedBookingSlot,
    [jobSurvey]
  );

  const createSurvey = useCallback(() => {
    if (job) {
      postCreateJobSurvey({ id: job.id }).then(() => refetchJobSurvey());
    }
  }, [job, refetchJobSurvey]);

  const handleDeleteSurveyBooking = async () => {
    if (surveyBooking) {
      try {
        await deleteSurveyBooking({ id: surveyBooking.id });
        window.location.reload();
      } catch (error) {
        handleUnknownDetail(error);
      }
    }
  };
  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        {jobSurveyError && (
          <div
            id="job-survey-error"
            className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
          >
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 max-w-[220px] rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">There currently is no survey for this job.</p>
              <Button onClick={createSurvey}>Create</Button>
            </div>
          </div>
        )}
        {surveyCompletedLock && (
          <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]">
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 flex max-w-[220px] flex-col gap-2 rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">This survey is currently completed and locked for editing.</p>
              <Button id="unlock-and-edit-survey-button" onClick={() => setSurveyCompletedLock(false)}>
                Unlock and Edit
              </Button>
            </div>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h1>Survey details:</h1>
            <div className="flex flex-row items-center gap-2">
              {showClearRequestButton && (
                <Button style="secondary" onClick={() => setShowClearRequestModal(true)}>
                  Clear request
                </Button>
              )}
              <button
                type="button"
                id="delete-survey-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowDeleteModal(true)}
              >
                <MdDelete size={20} className="text-error" />
              </button>
              <button
                type="button"
                id="edit-survey-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowModal(true)}
              >
                <MdEdit size={20} className="text-primary" />
              </button>
            </div>
          </div>
          <JobSurveyStatus surveyStatuses={surveyStatuses} job={job} />
          <div className="mt-2 flex flex-col">
            <div className="flex flex-1 flex-col">
              {Object.entries(data).map(([key, value], i) => (
                <div key={i} className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row">
                  <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
                    {key}
                  </p>
                  <p
                    id={`access-${key.toLowerCase().replace(':', '').replaceAll(' ', '-')}`}
                    className="min-h-10 flex-1 self-stretch whitespace-normal break-all p-2 md:px-4"
                  >
                    {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <Button onClick={() => setShowCompletionModal(true)}>Mark survey as complete and generate report</Button>
        </div>
      </div>
      <Link
        to={`/properties/${job?.propertyId}/report`}
        className={reportButtonDisabled ? 'pointer-events-none' : ''}
        aria-disabled={reportButtonDisabled}
        tabIndex={reportButtonDisabled ? -1 : undefined}
      >
        <Button style="secondary" disabled={reportButtonDisabled}>
          Go to report
        </Button>
      </Link>
      {showModal && (
        <JobSurveyModal
          onClose={() => {
            refetchJob();
            refetchSurveyBooking();
            refetchJobSurvey();
            setShowModal(false);
          }}
          surveyBooking={surveyBooking}
          survey={jobSurvey}
        />
      )}
      {showDeleteModal && surveyBooking && (
        <DeleteModal
          value="survey booking details"
          onClose={() => setShowDeleteModal(false)}
          callback={handleDeleteSurveyBooking}
        />
      )}
      {showCompletionModal && (
        <JobSurveyCompletionModal
          onClose={() => setShowCompletionModal(false)}
          job={job}
          jobSurvey={jobSurvey}
          refetchJobSurvey={refetchJobSurvey}
        />
      )}
      {showClearRequestModal && (
        <ClearRequestModal
          onClose={() => setShowClearRequestModal(false)}
          jobSurvey={jobSurvey}
          refetchSurveyBooking={refetchSurveyBooking}
          refetchJobSurvey={refetchJobSurvey}
        />
      )}
    </>
  );
};

export default JobSurveyData;
