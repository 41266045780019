import { Absence, AbsenceFormData } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';
import { isSameDay } from 'date-fns';

export const isAmAbsence = (absenceDate: Absence, date: Date) => {
  const startDate = new Date(absenceDate.absenceDateStart);
  const endDate = new Date(absenceDate.absenceDateEnd);

  // Multiple days absence
  if (startDate.toDateString() !== endDate.toDateString()) {
    // The start date of a multiple days absence
    if (isSameDay(startDate, date)) {
      // Block the entire day if the absence starts in the morning
      if (startDate.getHours() < 12) {
        return true;
      }
      // If the absence starts in the afternoon, only blocks PM
      return false;
    }

    // End date of a multiple days absence
    if (isSameDay(endDate, date)) {
      // If the absence ends in the afternoon, block the whole day
      return true;
    }

    // In-between dates should be fully blocked
    if (date > startDate && date < endDate) {
      return true;
    }

    return false;
  }

  // Single day absence
  return isSameDay(startDate, date) && startDate.getHours() < 12;
};

export const isPmAbsence = (absenceDate: Absence, date: Date) => {
  const startDate = new Date(absenceDate.absenceDateStart);
  const endDate = new Date(absenceDate.absenceDateEnd);

  // Absence starts in the morning and ends in the afternoon on the same day
  if (isSameDay(startDate, endDate) && startDate.getHours() < 12 && endDate.getHours() >= 12) {
    return isSameDay(startDate, date); // Block the entire day if it matches the absence date
  }

  // Multiple days absence
  if (startDate.toDateString() !== endDate.toDateString()) {
    // Handle the start date of a multiple days absence
    if (isSameDay(startDate, date)) {
      // Block the entire day if the absence starts in the morning
      if (startDate.getHours() < 12) {
        return true;
      }
      // If the absence starts in the afternoon, only block PM
      return true;
    }

    // The date of a multiple days absence
    if (isSameDay(endDate, date)) {
      // Block only the morning if the absence ends in the morning
      if (endDate.getHours() < 12) {
        return false; // Doesn't block the whole day
      }
      // Block the entire day if the absence ends in the PM
      return true;
    }

    // In-between dates should be fully blocked
    if (date > startDate && date < endDate) {
      return true;
    }

    return false;
  }

  // Handle single-day absence
  return isSameDay(startDate, date) && startDate.getHours() >= 12;
};

export const isFullDayAbsence = (absenceDate: Absence, date: Date) => {
  return isAmAbsence(absenceDate, date) && isPmAbsence(absenceDate, date);
};

export const checkBookingConflicts = (data: AbsenceFormData, bookings: Booking[]): boolean => {
  const selectedStartDate = new Date(data.absenceDateStart).toISOString().split('T')[0];
  const selectedEndDate = new Date(data.absenceDateEnd).toISOString().split('T')[0];
  const selectedEndSlot = data.slotEnd;

  let bookingConflict = false;

  bookings?.forEach((b) => {
    const surveyDate = new Date(b.surveyDate).toISOString().split('T')[0];
    const surveySlot = b.slot === 1 ? 'am' : 'pm';

    if (
      ((selectedStartDate < surveyDate && selectedEndDate > surveyDate) || // Booking is within the absence range
        (selectedStartDate <= surveyDate && selectedEndDate >= surveyDate)) && // Booking overlaps with the start or end of the absence
      !(
        (
          (selectedStartDate === surveyDate && selectedEndSlot === 'pm' && surveySlot === 'am') || // Allow PM absence if the booking is AM on the same day
          (selectedStartDate === surveyDate && selectedEndSlot === 'am' && surveySlot === 'pm')
        ) // Allow AM absence if the booking is PM on the same day
      )
    ) {
      bookingConflict = true;
    }
  });

  return bookingConflict;
};
