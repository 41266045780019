import React from 'react';
import { MdClose } from 'react-icons/md';
import { Button } from '@epcbuilder/lib/components';
import { Absence } from '@epcbuilder/lib/models/assessors';

export const DeleteAbsenceModal = ({
  value,
  onClose,
  callback,
}: {
  value: Absence;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  const formatDateTime = (dateString: string | Date) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button
        type="button"
        className="bg-modal-blur fixed inset-0 flex items-center justify-center opacity-20"
        onClick={onClose}
      />
      <div className="bg-modal border-primary relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:max-h-[600px] sm:w-full sm:max-w-[450px] sm:rounded-xl">
        <button
          type="button"
          className="text-primary-lighter absolute right-0 top-0 cursor-pointer p-4"
          onClick={onClose}
        >
          <MdClose size={24} />
        </button>
        <p className="flex justify-center font-bold">Are you sure?</p>
        <p className="mt-4">
          You are about to remove absence from{' '}
          <span className="font-bold">{formatDateTime(value.absenceDateStart)}</span> until{' '}
          <span className="font-bold">{formatDateTime(value.absenceDateEnd)}</span> ?
        </p>
        <div className="mt-8 flex flex-row justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>

          <button
            className="bg-blue text-dark border-primary-lighter dark:bg-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest h-12 w-full rounded-[24px] border-2 px-4 font-bold dark:border-none"
            id="absence-delete-confirm"
            onClick={async () => {
              await callback();
              onClose();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAbsenceModal;
