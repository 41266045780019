import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, TextInput } from '@epcbuilder/lib/components';
import { Job } from '@epcbuilder/lib/models/jobs';
import { AddNoteForm } from '@/models/job';
import { addJobNote } from '@/network/jobs';

const AddNoteToJobModal = ({ job, onClose }: { job: Job; onClose: () => void }) => {
  const { register, watch } = useForm<AddNoteForm>({
    reValidateMode: 'onSubmit',
  });
  const content = watch('content');

  const handleCompanyRequest = useCallback(async () => {
    try {
      await addJobNote({ id: job?.id, content: content });
      toast.success('Successfully added note', { toastId: 'add-note-success' });
      onClose();
    } catch (error: unknown) {
      toast.error('There was a problem saving the note. Please try again.');
      onClose();
    }
  }, [job, content, onClose]);

  return (
    <Modal id="add-user-to-company-modal" onClose={onClose}>
      <h1>Add note to job</h1>
      <p className="text-sm">
        This note will be available for viewing and will be visible to users and property owners via subject access data
        requests.
      </p>
      <div className="mt-4 w-full sm:self-end">
        <TextInput
          {...register('content')}
          type="text"
          id="content"
          value={content}
          name="content"
          title="Note"
          placeholder="Enter note message here"
          autoComplete="off"
        />
      </div>
      <div className="mt-4 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="add-confirm"
            style="primary"
            onClick={async () => {
              handleCompanyRequest();
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNoteToJobModal;
