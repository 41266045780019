import { api } from '@epcbuilder/lib/utils';
import { InstallationSaveBooking } from '@/models/job';

export const getInstallationBooking = async ({ id }: { id: string }) => {
  return await api.get(`/installation/${id}/booking`);
};

export const getInstallationStatuses = async () => {
  return await api.get(`/installation/statuses`);
};

export const postInstallationStatus = async ({
  id,
  installationStatusId,
}: {
  id?: string;
  installationStatusId: number;
}) => {
  return await api.post(`/installation/${id}/status`, { installationStatusId });
};

export const postInstallationSaveBooking = async ({ id, data }: { id?: string; data: InstallationSaveBooking }) => {
  return await api.post(`/installation/${id}/booking`, data);
};

export const deleteInstallationBooking = async ({ id }: { id?: string }) => {
  return await api.delete(`/installation/${id}/booking`);
};

export const postInstallationComplete = async ({ id, data }: { id?: string; data: { newRating: string } }) => {
  return await api.post(`/installation/${id}/complete`, data);
};

export const putInstallationBookingImprovement = async ({
  id,
  improvementId,
}: {
  id?: string;
  improvementId?: string;
}) => {
  return await api.put(`/installation/${id}/improvement/${improvementId}`);
};

export const deleteInstallationBookingImprovement = async ({ id }: { id?: string }) => {
  return await api.delete(`/installation/${id}/selected-improvement`);
};
