import useSWR from 'swr';
import { JobReport } from '@/models/job';
import { getJobReport } from '@/network/jobs';

const useJobReport = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<JobReport>(id ? `job-report-${id}` : null, async () =>
    id ? await getJobReport({ id }) : null
  );

  return {
    jobReport: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobReport;
