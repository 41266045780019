import useSWR from 'swr';
import { SurveyInstallationStatuses } from '@/models/job';
import { getInstallationStatuses } from '@/network/installations';

const useInstallationStatuses = () => {
  const { data, error, mutate, isLoading } = useSWR<SurveyInstallationStatuses[]>(
    `installation-statuses`,
    async () => await getInstallationStatuses()
  );

  return {
    installationStatuses: data,
    error,
    mutate,
    isLoading,
  };
};

export default useInstallationStatuses;
