import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from '@epcbuilder/lib/components';
import { Booking } from '@epcbuilder/lib/models/booking';
import { format } from 'date-fns';
import useProperty from '@/hooks/properties/useProperty';

const AssessmentCard = ({ id, booking, date }: { id: string; booking: Booking; date?: Date }) => {
  const navigate = useNavigate();
  const { property } = useProperty({ id: booking.propertyId });

  const formattedDate = date ? format(new Date(date), 'dd/MM/yyyy') : '';

  return (
    <Card key={id} className="border-blue w-full overflow-hidden rounded-[16px] border-2 bg-white xl:min-h-[200px]">
      <div className="flex w-full flex-col xl:gap-0 xl:pt-2">
        <div className="min-h-[10rem]">
          <div className="mb-4 flex w-full justify-between">
            <p className="font-bold">{property?.userFullName}</p>
            {formattedDate && <p className="justify-end italic">{formattedDate}</p>}
          </div>
          <div className="mb-4 min-h-[6rem] flex-col">
            <p>{property?.addressLine1}</p>
            <p>{property?.addressLine2}</p>
            <p>{property?.city}</p>
            <p>{property?.postcode}</p>
          </div>
          <div className="bg-blue mb-[2rem] h-[2px] w-full"></div>
          {/* TODO: */}
          {/* <p>Distance from the office</p> */}
        </div>
        <div className="m-auto flex items-end">
          <Button
            className="hover:border-primary-lighter bg-blue font-header border-primary-lighter m-auto flex h-12 min-w-fit rounded-[24px] px-8 py-2 shadow"
            style="secondary"
            onClick={() => navigate(`/properties/${booking.propertyId}?tab=job`)}
            overrideBaseClassnames
          >
            <p className="text-color flex flex-row">View Job</p>
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default AssessmentCard;
