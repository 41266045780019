import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, TextInput } from '@epcbuilder/lib/components';
import Modal from '@epcbuilder/lib/components/Modal';
import { User } from '@epcbuilder/lib/models/user';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EditUser } from '@/models/properties';
import { putUser } from '@/network/users';

const editUserSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  secondaryPhone: yup
    .string()
    .matches(PHONE_REGEX, { message: 'Secondary phone is not a valid UK phone number', excludeEmptyString: true })
    .nullable(),
});

const EditUserModal = ({ user, onClose }: { user: User; onClose: () => void }) => {
  const defaultValues: EditUser = {
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    phone: user.phone || '',
    secondaryPhone: user?.secondaryPhone || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<EditUser>({
    defaultValues,
    resolver: yupResolver(editUserSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<EditUser> = async (data) => {
    try {
      await putUser({
        id: user.id,
        data: {
          email: data.email.trim(),
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone.trim(),
          secondaryPhone: data.secondaryPhone?.trim() || undefined,
        },
      });
      toast.success('User successfully updated', { toastId: 'edit-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<EditUser>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="edit-modal" onClose={onClose}>
      <h1>Edit User</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          id="email"
          name="email"
          title="User's email address"
          placeholder="Email"
          label="Email Name"
          error={errors.email?.message}
        />
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="User's first name"
          placeholder="First name"
          label="First Name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="User's last name"
          placeholder="Last name"
          label="Last Name"
          error={errors.lastName?.message}
        />
        <TextInput
          {...register('phone')}
          id="phone"
          name="phone"
          title="User's phone number"
          placeholder="Phone"
          label="Phone"
          error={errors.phone?.message}
        />

        <TextInput
          {...register('secondaryPhone')}
          id="secondaryPhone"
          name="secondaryPhone"
          title="User's secondary phone number"
          label="Secondary Phone"
          placeholder="Secondary Phone (optional)"
          error={errors.secondaryPhone?.message}
        />

        <div className="mt-2 flex flex-row gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="edit-submit" loading={isSubmitting} type="submit">
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditUserModal;
