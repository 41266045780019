import React from 'react';
import { Control, FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { Checkbox, FiltersContainer, Select, TextInput } from '@epcbuilder/lib/components';
import { jobStatusOptions } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { Filters } from '@/models/properties';

const FiltersForm = <T extends FieldValues>({
  control,
  register,
  errors,
  reset,
}: {
  control: Control<T>;
  register: UseFormRegister<Filters>;
  errors: FieldErrors<Filters>;
  reset: () => void;
}) => {
  return (
    <FiltersContainer id="properties-filters" reset={reset}>
      <div className="w-52">
        <Select
          multiple={true}
          control={control}
          id="epcCurrent"
          name={'currentEpcFilters' as Path<T>}
          title="Filter by current EPC rating"
          label="Current EPC"
          placeholder="Filter Current EPC"
          options={epcRatingOptions}
        />
      </div>
      <div className="w-52">
        <Select
          multiple={true}
          control={control}
          id="jobStatus"
          name={'jobStatusFilters' as Path<T>}
          title="Filter by status"
          label="Job Status"
          placeholder="Filter Job Status"
          options={jobStatusOptions}
        />
      </div>
      <div className="w-full sm:w-96 sm:self-end">
        <TextInput
          {...register('search')}
          id="search"
          name="search"
          title="your search query"
          placeholder="Search"
          error={errors.search?.message}
        />
      </div>
      <div className="flex h-full flex-col self-end">
        <div id="show-inactive-checkbox">
          <Checkbox id="showInactive" {...register('showInactive')} label="Include Inactive" />
        </div>
        <div id="show-eligible-only-checkbox">
          <Checkbox id="showEligibleOnly" {...register('showEligibleOnly')} label="Show Eligible Only" />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default FiltersForm;
