import React, { useCallback, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { Note } from '@epcbuilder/lib/models/jobs';
import useLoggedInUser from '@/hooks/users/useLoggedInUser';
import { disableNote } from '@/network/jobs';

const JobNote = ({ note, refetchJobNotes }: { note: Note | undefined; refetchJobNotes: () => void }) => {
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const { authId } = useLoggedInUser();

  const handleDeleteNote = useCallback(async () => {
    try {
      await disableNote({ id: note!.id });
      refetchJobNotes();
      toast.success('Successfully removed note', { toastId: 'remove-note-sucess' });
    } catch (error: unknown) {
      toast.error('There was a problem removing the note. Please try again.');
    }
  }, [note, refetchJobNotes]);

  return (
    <>
      <div className={`flex flex-row ${note?.position == 'right' ? 'justify-end' : 'justify-start'}`}>
        <div
          className={`border-neutral-dark bg-color hover:border-primary ${note?.authorId == authId ? 'opacity-100' : 'opacity-60'} relative mt-2 w-4/5 gap-4 rounded-xl border-2 p-4 pr-8`}
        >
          <div className="flex">
            <div className="rows-3 items-center">
              <h4>
                {note?.author}
                <span className="text-sm font-normal italic">{note?.created}</span>
              </h4>
              <p>{note?.content}</p>
              {authId == note?.authorId && (
                <button
                  type="button"
                  title="Delete note"
                  id="delete-note-icon"
                  className="absolute right-0 top-0 flex cursor-pointer p-2"
                  onClick={() => setDeleteModal(true)}
                >
                  <MdDelete size={20} className="text-primary" />
                </button>
              )}
            </div>
          </div>
        </div>

        {showDeleteModal && (
          <DeleteModal
            value="this note"
            onClose={() => {
              setDeleteModal(false);
            }}
            callback={handleDeleteNote}
          />
        )}
      </div>
    </>
  );
};

export default JobNote;
