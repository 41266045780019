import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Select } from '@epcbuilder/lib/components';
import { PropertyStatus, statusOptions } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { patchPropertyStatus } from '@/network/properties';

const PropertyStatusForm = ({ id, status }: { id: string; status: PropertyStatus | undefined }) => {
  const defaultValues = {
    status,
  };

  const { control, watch, setError } = useForm<{ status: PropertyStatus }>({ defaultValues });
  const value = watch('status');

  const handleStatusUpdate = useCallback(async () => {
    try {
      await patchPropertyStatus({ id, status: value });
      toast.success('Property status successfully updated', { toastId: 'status-success' });
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<{ status: PropertyStatus }>(setError, errors);
      handleUnknownDetail(error);
    }
  }, [id, setError, value]);

  useEffect(() => {
    if (value !== status) {
      handleStatusUpdate();
    }
  }, [handleStatusUpdate, status, value]);

  return (
    <div className="w-48">
      <Select
        control={control}
        id="status"
        name="status"
        title="select property status"
        label="Status"
        placeholder=""
        options={statusOptions}
      />
    </div>
  );
};

export default PropertyStatusForm;
