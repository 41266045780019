import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Button } from '@epcbuilder/lib/components';
import { format } from 'date-fns';
import usePropertyRating from '@/hooks/properties/usePropertyRating';
import PropertyRatingModal from './modals/PropertyRatingModal';

const PropertyRatingData = ({ id }: { id: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const { propertyRating, mutate, error } = usePropertyRating({ id });

  const data = {
    'LMK Key:': propertyRating?.lmkKey,
    'Certificate Number:': propertyRating?.certificateNumber,
    'Certificate Valid Until': propertyRating?.certificateValidUntil
      ? format(new Date(propertyRating?.certificateValidUntil), 'dd/MM/yyyy')
      : '',
    'Current Rating:': propertyRating?.currentRating,
    'Potential Rating:': propertyRating?.potentialRating,
    'Current Energy Rating:': propertyRating?.currentEnergyEfficiency,
    'Potential Energy Rating:': propertyRating?.potentialEnergyEfficiency,
    'Current Co2 Emissions:': propertyRating?.co2EmissionsCurrent,
    'Potential Co2 Emissions:': propertyRating?.co2EmissionsPotential,
    'Built Form:': propertyRating?.builtForm,
    'Construction Age Band:': propertyRating?.constructionAgeBand,
    'Wall Description:': propertyRating?.wallDescription,
    'Floor Description:': propertyRating?.floorDescription,
    'Property Type:': propertyRating?.propertyType,
    'Manual Entry:': propertyRating?.isManualEntry ? 'Yes' : 'No',
  };

  const isError = error && (error.detail === "Rating doesn't exist" || error.detail === 'Rating is deleted.');

  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-between">
            <h1>Rating:</h1>
            {!isError && (
              <button
                type="button"
                id="rating-button"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => {
                  setEdit(true);
                  setShowModal(true);
                }}
              >
                <MdEdit size={20} className="text-primary" />
              </button>
            )}
          </div>
          {isError ? (
            <div>Rating does not exist</div>
          ) : (
            <div className="mt-2 flex flex-col">
              <div className="flex flex-1 flex-col">
                {Object.entries(data).map(([key, value], i) => (
                  <div
                    key={i}
                    className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row"
                  >
                    <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
                      {key}
                    </p>
                    <p
                      id={key.toLowerCase().replace(':', '').replace('?', '').replaceAll(' ', '-')}
                      className="min-h-10 flex-1 self-stretch whitespace-normal break-all p-2 md:px-4"
                    >
                      {value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {isError && (
          <div id="new-rating-button" className="mt-4">
            <Button onClick={() => setShowModal(true)}>Create New</Button>
          </div>
        )}
      </div>
      {showModal && (
        <PropertyRatingModal
          id={id}
          onClose={() => {
            mutate();
            setShowModal(false);
            setEdit(false);
          }}
          propertyRating={edit ? propertyRating : undefined}
        />
      )}
    </>
  );
};

export default PropertyRatingData;
