import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, CardTooltip, Modal, TextInput } from '@epcbuilder/lib/components';
import { Job } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { patchPashubId } from '@/network/jobs';

const JobDetailsModal = ({ onClose, job }: { onClose: () => void; job: Job }) => {
  const defaultValues = {
    pashubid: job?.pasHubJobId,
  };

  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ pashubid: string }>({ defaultValues });

  const onSubmit: SubmitHandler<{ pashubid: string }> = async () => {
    try {
      await patchPashubId({ id: job.id, pashubid: getValues('pashubid') });
      toast.success('Job Pashub ID Updated', { toastId: 'job-pashubid-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<{ pashubid: string }>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const onClickBlankReport = async () => {
    try {
      await patchPashubId({ id: job.id, pashubid: undefined });
      toast.success('Using blank report for job'), { toastId: 'job-pashubid-success' };
      onClose();
    } catch (error: unknown) {
      toast.error('There was an error bypassing Pashub ID. Please try again.');
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal id="job-pashubid-modal" onClose={onClose}>
      <h1>Edit Job Pashub ID</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput id="pashubid" {...register('pashubid')} label="Pashub ID" error={errors.pashubid?.message} />
        <div className="between relative flex flex-row space-x-2">
          <button type="button" className="relative" onClick={onClickBlankReport}>
            Generate blank report
          </button>
          <CardTooltip
            id="report-tooltip"
            size={16}
            iconClasses="top-[-8px] left-[158px] justify-start"
            text="Bypasses Pashub ID and generates a report without images/notes"
          />
        </div>
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="pashubid-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default JobDetailsModal;
