import React, { useEffect } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, FullPage, Loading } from '@epcbuilder/lib/components';
import useProperty from '@/hooks/properties/useProperty';
import ReportPDF from './ReportPDF';

const PropertyReport = ({ id }: { id: string }) => {
  const { property, mutate: refetchProperty, error, isLoading } = useProperty({ id });

  useEffect(() => {
    if (error) {
      const { detail } = error;
      if (detail === "Property doesn't exist." || detail === 'Property is deleted.') {
        toast.error('This property does not exist, please go back and try again.');
      }
    }
  }, [error]);

  if (isLoading || !property) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <>
      <div className="flex flex-col p-4 sm:p-8">
        <div className="flex flex-row items-center gap-8">
          <Link to="/properties" className="flex flex-row items-center gap-2">
            <MdKeyboardArrowLeft size={20} /> Back
          </Link>
          <p className="text-xl">{`${property?.addressLine1}, ${property?.addressLine2} Report`}</p>
        </div>
        <Container>
          <div className="flex h-20 items-center justify-center md:hidden">
            <p className="text-center">The report can only be viewed on a screen wider than 768px</p>
          </div>
          <div className="hidden md:block">
            <ReportPDF property={property} refetchProperty={refetchProperty} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default PropertyReport;
