import { ImprovementItem } from '../models/jobs';

const useImprovementCostCalculation = (items: ImprovementItem[]) => {
  let cost = 0;

  if (items.length == 0) return 0;

  items.forEach((x) => {
    cost += x.itemCost * (x.improvementItemType?.isDiscount ?? false ? -1 : 1);
  });

  return cost < 0 ? 0 : cost;
};

export default useImprovementCostCalculation;
