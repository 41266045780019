import { api } from '@epcbuilder/lib/utils';
import { EditUser } from '@/models/properties';

export const getUsers = async () => {
  return await api.get('/users');
};

export const deleteUser = async ({ id }: { id: string }) => {
  return await api.delete(`/users/${id}`);
};

export const putUser = async ({ id, data }: { id: string; data: EditUser }) => {
  return await api.put(`/users/${id}`, data);
};

export const patchUserPassword = async ({ id, password }: { id: string; password: string }) => {
  return await api.patch(`/users/${id}/updatepassword`, { password });
};

export const getUserProperties = async ({ id }: { id: string }) => {
  return await api.get(`/user/${id}/properties`);
};

export const restoreUser = async ({ id }: { id: string }) => {
  return await api.patch(`/users/${id}/restore`);
};
