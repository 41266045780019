import React from 'react';
import { Helmet } from 'react-helmet';
import { AdminsTable } from '@/components/admins';
import Layout from '@/components/Layout';
import withAuth from '@/hoc/withAuth';

const Admins = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Admin - Admins</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Admins">
        <AdminsTable />
      </Layout>
    </>
  );
};

export default withAuth(Admins);
