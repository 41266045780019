import useSWR from 'swr';
import { SurveyInstallationStatuses } from '@/models/job';
import { getSurveyStatuses } from '@/network/surveys';

const useSurveyStatuses = () => {
  const { data, error, mutate, isLoading } = useSWR<SurveyInstallationStatuses[]>(
    `survey-statuses`,
    async () => await getSurveyStatuses()
  );

  return {
    surveyStatuses: data,
    error,
    mutate,
    isLoading,
  };
};

export default useSurveyStatuses;
