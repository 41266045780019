import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Property } from '@epcbuilder/lib/models/properties';
import PropertyAddressModal from './modals/PropertyAddressModal';

const PropertyAddressData = ({ property, mutate }: { property: Property; mutate: () => void }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-between">
            <h1>Address:</h1>
            <button
              type="button"
              id="address-button"
              className="flex cursor-pointer items-center justify-center p-2"
              onClick={() => setShowModal(true)}
            >
              <MdEdit size={20} className="text-primary" />
            </button>
          </div>
          <p id="line1-line2">{`${property?.addressLine1} ${property?.addressLine2}`}</p>
          <p id="city-postcode">{`${property?.city} ${property?.postcode}`}</p>
        </div>
        <div className="text-primary absolute right-0 top-0 m-2"></div>
      </div>
      {showModal && (
        <PropertyAddressModal
          onClose={() => {
            mutate();
            setShowModal(false);
          }}
          property={property}
        />
      )}
    </>
  );
};

export default PropertyAddressData;
