import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, Select } from '@epcbuilder/lib/components';
import { Job, JobSurvey } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { isEmptyGuid } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { getOptionByValue } from '@epcbuilder/lib/utils/generic';
import { postJobGenerateReport } from '@/network/jobs';
import { getDoesReportAlreadyExists, putReportActualEpc } from '@/network/report';
import { postSurveyComplete } from '@/network/surveys';

const JobSurveyCompletionModal = ({
  onClose,
  job,
  jobSurvey,
  refetchJobSurvey,
}: {
  onClose: () => void;
  job: Job | undefined;
  jobSurvey: JobSurvey | undefined;
  refetchJobSurvey: () => void;
}) => {
  const defaultValues = {
    actualRating: '',
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ actualRating: string }>({ defaultValues });

  const onSubmit: SubmitHandler<{ actualRating: string }> = async (data) => {
    try {
      const optionLabel = getOptionByValue(epcRatingOptions, data.actualRating)?.label;
      if (!optionLabel) return;

      await postSurveyComplete({
        id: jobSurvey?.id,
        data: { actualRating: optionLabel },
      });

      const reportAlreadyExists = await getDoesReportAlreadyExists({ jobId: job?.id });
      if (reportAlreadyExists) {
        await putReportActualEpc({
          jobId: job?.id,
          data: { rating: optionLabel },
        });
      } else {
        await postJobGenerateReport({ id: job?.id });
      }
      toast.success('Survey marked as complete.');
      refetchJobSurvey();
      onClose();
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'PasHub ID must be set to complete survey.':
          toast.error('PasHub ID must be entered before a survey can be marked as complete.');
          break;
        case 'Property Rating could not be found':
          toast.error('Property Rating details must be entered before a survey can be marked as complete.');
          break;
        case 'Survey must be available with a valid booking date':
          toast.error('Survey booking must be entered before a survey can be marked as complete.');
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <h1>Complete Survey</h1>
      {isEmptyGuid(job?.pasHubJobId) && (
        <p className="mt-4">Note: a blank report will be generated as no Pashub ID has been provided for the job.</p>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4 flex flex-col gap-4">
        <Select
          control={control}
          id="actualRating"
          name="actualRating"
          title="select actual epc rating"
          label="Actual EPC Rating"
          placeholder=""
          options={epcRatingOptions}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default JobSurveyCompletionModal;
