import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthLayout, CookieModal } from '@epcbuilder/lib/components';
import { LoginForm } from '@/components/login';

const Login = () => {
  return (
    <>
      <CookieModal marketingUrl={import.meta.env.VITE_MARKETING_URL} />
      <Helmet>
        <title>EPC Builder - Admin</title>
        <meta name="description" content="" />
      </Helmet>
      <AuthLayout header="Login">
        <div className="absolute left-0 top-0 m-4">
          <h1>Admin</h1>
        </div>
        <LoginForm />
      </AuthLayout>
    </>
  );
};

export default Login;
