import React from 'react';
import { Helmet } from 'react-helmet';
import AssessorsTable from '@/components/assessors/AssessorsTable';
import Layout from '@/components/Layout';
import withAuth from '@/hoc/withAuth';

const Assessors = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Admin - Assessors</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Assessors">
        <AssessorsTable />
      </Layout>
    </>
  );
};

export default withAuth(Assessors);
