import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { LuExternalLink } from 'react-icons/lu';
import { MdLogout, MdOutlineManageAccounts, MdOutlinePeopleOutline, MdPerson } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '@epcbuilder/lib/context/authContext';

enum MenuItems {
  PROPERTIES = 'properties',
  ADMINS = 'admins',
  USERS = 'users',
  ASSESSORS = 'assessors',
}

const MenuItem = ({
  id,
  icon,
  text,
  href,
  active,
  setActive,
}: {
  id: MenuItems;
  icon: JSX.Element;
  text: string;
  href: string;
  active: MenuItems;
  setActive: Dispatch<SetStateAction<MenuItems>>;
}) => {
  const baseClassNames = 'flex flex-row items-center h-12 rounded-xl px-3 cursor-pointer border-2';

  return (
    <Link
      to={href}
      id={`sidebar-${id}`}
      className={`${baseClassNames} ${
        active === id
          ? 'border-primary-lighter bg-blue text-dark'
          : 'text-color hover:border-primary-lighter border-transparent'
      }`}
      onClick={() => setActive(id)}
    >
      {icon}
      <p id={active === id ? 'sidebar-active' : undefined} className="ml-4 hidden group-hover:block lg:block">
        {text}
      </p>
    </Link>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchLogout } = useContext(AuthContext);

  const [active, setActive] = useState<MenuItems>(() => {
    const pathSegment = location.pathname.split('/')[1];

    if (pathSegment === 'assessor') {
      return MenuItems.ASSESSORS;
    }

    return pathSegment as MenuItems;
  });

  return (
    <div
      id="sidebar"
      className="max-w-18 bg-color-white border-neutral group fixed left-0 z-10 hidden h-screen w-16 border-r-2 pt-24 shadow hover:w-48 sm:block lg:w-48"
    >
      <div className="flex h-full flex-col justify-between">
        <div className="mt-4 flex flex-1 flex-col gap-2 px-2 py-4">
          <MenuItem
            id={MenuItems.PROPERTIES}
            href="/properties"
            icon={<PiHouseSimpleBold size={20} />}
            text="Properties"
            active={active}
            setActive={setActive}
          />
          <MenuItem
            id={MenuItems.ADMINS}
            href="/admins"
            icon={<MdOutlineManageAccounts size={20} />}
            text="Admins"
            active={active}
            setActive={setActive}
          />
          <MenuItem
            id={MenuItems.USERS}
            href="/users"
            icon={<MdPerson size={20} />}
            text="Users"
            active={active}
            setActive={setActive}
          />
          <MenuItem
            id={MenuItems.ASSESSORS}
            href="/assessors"
            icon={<MdOutlinePeopleOutline size={20} />}
            text="Assessors"
            active={active}
            setActive={setActive}
          />
        </div>
        <div className="flex flex-col gap-2 px-2 py-4">
          <a
            id="sidebar-epc-builder"
            className="text-color border-blue hover:border-primary-lighter flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 px-3"
            target="_blank"
            href={import.meta.env.VITE_WEB_URL}
            rel="noopener noreferrer"
          >
            <LuExternalLink size={20} />
            <p className="ml-4 hidden group-hover:block lg:block">EPC Builder</p>
          </a>
          <button
            type="button"
            id="sidebar-logout"
            className="text-color border-blue hover:border-primary-lighter flex h-12 cursor-pointer flex-row items-center rounded-xl border-2 px-3"
            onClick={() => {
              dispatchLogout();
              navigate('/');
            }}
          >
            <MdLogout size={20} />
            <p className="ml-4 hidden group-hover:block lg:block">Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
