import { Note } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getJobNotes } from '@/network/jobs';

const useJobNotes = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Note[]>(id ? `job-notes-${id}` : null, async () =>
    id ? await getJobNotes({ id }) : null
  );

  return {
    jobNotes: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobNotes;
