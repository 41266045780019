import { PropertyAccessDetails, PropertyStatus } from '@epcbuilder/lib/models/properties';
import { api } from '@epcbuilder/lib/utils';
import { PropertyAddressRequest, PropertyRatingRequest } from '@/models/properties';

export const getProperties = async ({
  search,
  page,
  limit,
  currentEpcFilters,
  potentialEpcFilters,
  jobStatusFilters,
  showInactive,
  showEligibleOnly,
}: {
  search: string;
  page: number;
  limit: number;
  currentEpcFilters: number[];
  potentialEpcFilters: number[];
  jobStatusFilters: number[];
  showInactive: boolean;
  showEligibleOnly: boolean;
}) => {
  let requestUrl = '/properties?';

  if (page) {
    requestUrl = `${requestUrl}&page=${page}`;
  }

  if (limit) {
    requestUrl = `${requestUrl}&limit=${limit}`;
  }

  if (search) {
    requestUrl = `${requestUrl}&searchterm=${search}`;
  }

  if (currentEpcFilters.length > 0) {
    for (const value of currentEpcFilters) {
      requestUrl = `${requestUrl}&currentepcfilters=${value}`;
    }
  }

  if (potentialEpcFilters.length > 0) {
    for (const value of potentialEpcFilters) {
      requestUrl = `${requestUrl}&potentialepcfilters=${value}`;
    }
  }

  if (jobStatusFilters.length > 0) {
    for (const value of jobStatusFilters) {
      requestUrl = `${requestUrl}&jobstatusfilters=${value}`;
    }
  }

  if (showEligibleOnly) {
    requestUrl = `${requestUrl}&showEligibleOnly=true`;
  }

  if (showInactive) {
    requestUrl = `${requestUrl}&showInactive=true`;
  }

  return await api.get(requestUrl);
};

export const getProperty = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}`);
};

export const getPropertyUser = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}/user`);
};

export const getPropertyRating = async ({ id }: { id: string }) => {
  return await api.get(`/properties/${id}/rating`);
};

export const patchPropertyStatus = async ({ id, status }: { id: string; status: PropertyStatus }) => {
  return await api.patch(`/properties/${id}/status`, { id, statusType: status });
};

export const postPropertyRating = async ({ id, data }: { id: string; data: PropertyRatingRequest }) => {
  return await api.post(`/properties/${id}/rating`, data);
};

export const putPropertyRating = async ({
  id,
  propertyRatingId,
  data,
}: {
  id: string;
  propertyRatingId: string;
  data: PropertyRatingRequest;
}) => {
  return await api.put(`/properties/${id}/rating/${propertyRatingId}`, data);
};

export const putPropertyAddress = async ({ id, data }: { id: string; data: PropertyAddressRequest }) => {
  return await api.put(`/properties/${id}/address`, data);
};

export const putPropertyAccess = async ({ id, data }: { id: string; data: PropertyAccessDetails }) => {
  return await api.put(`/properties/${id}/access`, data);
};

export const getPropertyActiveJob = async ({ id }: { id?: string }) => {
  return await api.get(`/properties/${id}/active-job`);
};

export const getPropertyCurrentJob = async ({ id }: { id?: string }) => {
  return await api.get(`/properties/${id}/current-job`);
};
