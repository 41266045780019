import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, Modal, TextInput } from '@epcbuilder/lib/components';
import { Property } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData } from '@epcbuilder/lib/utils/api';
import { PropertyAddressRequest } from '@/models/properties';
import { putPropertyAddress } from '@/network/properties';

const PropertyAddressModal = ({ onClose, property }: { onClose: () => void; property: Property }) => {
  const defaultValues = {
    addressLine1: property.addressLine1,
    addressLine2: property.addressLine2,
    city: property.city,
    postcode: property.postcode,
  };

  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PropertyAddressRequest>({ defaultValues });

  const onSubmit: SubmitHandler<PropertyAddressRequest> = async () => {
    try {
      await putPropertyAddress({ id: property.id, data: getValues() });
      toast.success('Property Address Updated', { toastId: 'address-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<PropertyAddressRequest>(setError, errors);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="address-modal" onClose={onClose}>
      <h1>Edit Address</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          id="addressLine1"
          {...register('addressLine1')}
          label="Address Line 1"
          error={errors.addressLine1?.message}
        />
        <TextInput
          id="addressLine2"
          {...register('addressLine2')}
          label="Address Line 2"
          error={errors.addressLine2?.message}
        />
        <TextInput id="city" {...register('city')} label="City" error={errors.city?.message} />
        <TextInput id="postcode" {...register('postcode')} label="Postcode" error={errors.postcode?.message} />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="address-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PropertyAddressModal;
