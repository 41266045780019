import { Booking } from '@epcbuilder/lib/models/booking';
import useSWR from 'swr';
import { getAssessorSurveyBooking } from '@/network/assessors';

const useAssessorSurveyBooking = ({ id, start, end }: { id: string; start: Date; end: Date }) => {
  const { data, error, mutate, isLoading } = useSWR<Booking[]>(
    `assessor-booking-data-${id}`,
    async () => await getAssessorSurveyBooking({ id, start, end })
  );

  return {
    bookings: data,
    error,
    mutate,
    isLoading,
  };
};

export default useAssessorSurveyBooking;
