export const rotateBase64Image = async (base64Image: string): Promise<string> => {
  const img = new Image();
  img.src = base64Image;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('Canvas context is not supported');
        }
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(Math.PI / 2);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        const rotatedImage = canvas.toDataURL('image/jpeg');
        resolve(rotatedImage);
      } catch (error) {
        reject(error);
      }
    };
    img.onerror = (error) => reject(error);
  });
};
