import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdCheck, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Select } from '@epcbuilder/lib/components';
import { InstallationStatusStrings, Job } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { Option } from '@epcbuilder/lib/utils/generic';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import { SurveyInstallationStatuses } from '@/models/job';
import { postInstallationStatus } from '@/network/installations';

const JobInstallationStatus = ({
  installationStatuses,
  job,
}: {
  installationStatuses: SurveyInstallationStatuses[];
  job: Job | undefined;
}) => {
  const { jobInstallation, mutate: refetchJobInstallation } = useJobInstallation({ id: job?.id });

  const [editMode, setEditMode] = useState<boolean>(false);

  const defaultValues = {
    status: jobInstallation?.installationStatusId,
  };

  const statusOptions: Option<number>[] = installationStatuses?.map((status) => {
    return {
      value: status.key,
      label: status.value,
    };
  });

  const { control, watch, setError, setValue } = useForm<{ status: number }>({ defaultValues });
  const value = watch('status');

  const handleStatusUpdate = useCallback(async () => {
    try {
      await postInstallationStatus({ id: jobInstallation?.id, installationStatusId: value });
      toast.success('Installation status successfully updated', { toastId: 'installation-status-success' });
      await refetchJobInstallation();
      setEditMode(false);
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<{ status: number }>(setError, errors);
      handleUnknownDetail(error);
    }
  }, [jobInstallation?.id, refetchJobInstallation, setError, value]);

  useEffect(() => {
    if (jobInstallation) {
      setValue('status', jobInstallation.installationStatusId);
    }
  }, [jobInstallation, setValue]);

  return (
    <div className="flex flex-row gap-2">
      {editMode ? (
        <div>
          <Select
            inline={true}
            control={control}
            id="status"
            name="status"
            title="select installation status"
            label="Status"
            placeholder=""
            options={statusOptions}
            className="w-56"
          />
        </div>
      ) : (
        <>
          <p>Status:</p>
          <p className="font-bold">
            {jobInstallation?.installationStatusId
              ? InstallationStatusStrings[jobInstallation?.installationStatusId]
              : 'Unknown'}
          </p>
        </>
      )}
      {editMode ? (
        <button type="button" onClick={() => handleStatusUpdate()}>
          <MdCheck className="text-primary" />
        </button>
      ) : (
        <button type="button" onClick={() => setEditMode(true)}>
          <MdEdit className="text-primary" />
        </button>
      )}
    </div>
  );
};

export default JobInstallationStatus;
