import { Option } from '../utils/generic';

export type ActionMap<T> = {
  [Key in keyof T]: T[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: T[Key];
      };
};

export const booleanOptions: Option<boolean>[] = [
  {
    value: true,
    label: 'True',
  },
  {
    value: false,
    label: 'False',
  },
];

export const slotBookingOptions: Option<number>[] = [
  {
    value: 1,
    label: 'AM',
  },
  {
    value: 2,
    label: 'PM',
  },
];
