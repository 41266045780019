import React from 'react';
import { CiDroplet } from 'react-icons/ci';

const EpcRatingArrow = ({
  rating,
  propertyRating,
  className,
}: {
  rating: string;
  propertyRating: string;
  className: string;
}) => {
  return (
    <>
      <div
        className={`relative flex size-4 items-center justify-center ${
          propertyRating === rating ? 'visible' : 'invisible'
        }`}
      >
        <CiDroplet className={`${className} absolute -left-2 -top-1 -rotate-90`} size={25} />
        <p className={`${className} pt-[1px] text-xs font-bold`}>{`${rating}`}</p>
      </div>
    </>
  );
};

export default EpcRatingArrow;
