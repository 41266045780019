import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getProperties } from '@/network/properties';

const useProperties = ({
  search,
  page,
  limit,
  currentEpcFilters,
  potentialEpcFilters,
  jobStatusFilters,
  showInactive,
  showEligibleOnly,
}: {
  search: string;
  page: number;
  limit: number;
  currentEpcFilters: number[];
  potentialEpcFilters: number[];
  jobStatusFilters: number[];
  showInactive: boolean;
  showEligibleOnly: boolean;
}) => {
  const { data, error, isLoading, mutate } = useSWR<{ itemCount: number; items: Property[] }>(
    `properties-data-${search}-${page}-${limit}-${currentEpcFilters}-${potentialEpcFilters}-${jobStatusFilters}-${showInactive}-${showEligibleOnly}`,
    async () =>
      await getProperties({
        search,
        page,
        limit,
        currentEpcFilters,
        potentialEpcFilters,
        jobStatusFilters,
        showInactive,
        showEligibleOnly,
      }),
    {
      keepPreviousData: true,
    }
  );

  return {
    properties: data?.items,
    count: data?.itemCount,
    totalPages: data && data.itemCount && Math.ceil(data?.itemCount / limit),
    error,
    mutate,
    isLoading,
  };
};

export default useProperties;
