import useSWR from 'swr';
import { getLoggedInUser } from '@/network/admins';

const useLoggedInUser = () => {
  const { data, error, isLoading, mutate } = useSWR<number>('auth-user', async () => await getLoggedInUser());

  return {
    authId: data,
    error,
    mutate,
    isLoading,
  };
};

export default useLoggedInUser;
