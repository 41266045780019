import React, { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Booking } from '@epcbuilder/lib/models/booking';
import { format } from 'date-fns';
import AssessmentCard from './AssessmentCard';

const UpcomingAssessments = ({ bookings, selectedBooking }: { bookings: Booking[]; selectedBooking?: Booking }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const cardsPerPage = 4;

  const sortedBookings = bookings.sort((a, b) => new Date(a.surveyDate).getTime() - new Date(b.surveyDate).getTime());
  const totalPages = Math.ceil(sortedBookings.length / cardsPerPage);

  const formatSurveyDate = (surveyDate: Date) => {
    const date = new Date(surveyDate);
    const formattedDate = `${format(date, 'EEEE')} - ${format(date, "do 'of' MMMM")}`;
    const amPm = date.getHours() < 12 ? 'AM' : 'PM';
    return `${formattedDate} ${amPm}`;
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="mt-12">
      {!selectedBooking && (
        <div>
          <p className="flex flex-1 justify-start p-4 font-bold uppercase">Upcoming Assessments</p>
          <div className="flex flex-row items-center gap-6">
            {currentPage > 0 && (
              <button
                onClick={handlePreviousPage}
                className="border-blue dark:bg-dark rounded-[22px] border-2 bg-white p-2 text-lg"
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {sortedBookings
                .slice(currentPage * cardsPerPage, currentPage * cardsPerPage + cardsPerPage)
                .map((booking) => (
                  <AssessmentCard key={booking.id} booking={booking} id={booking.id} date={booking.surveyDate} />
                ))}
            </div>
            {currentPage < totalPages - 1 && (
              <button
                onClick={handleNextPage}
                className="border-blue dark:bg-dark rounded-[22px] border-2 bg-white p-2 text-lg"
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
          </div>
        </div>
      )}
      {selectedBooking && (
        <div>
          <p className="flex flex-1 justify-start p-4 font-bold uppercase">
            {formatSurveyDate(selectedBooking.surveyDate)}
          </p>
          <AssessmentCard id={selectedBooking.surveyId} booking={selectedBooking} />
        </div>
      )}
    </div>
  );
};

export default UpcomingAssessments;
