import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import 'react-datepicker/dist/react-datepicker.css';

export const FilteredDatePicker = <T extends FieldValues>({
  id,
  control,
  name,
  label,
  inline = false,
  showTimeSelect = false,
  daysToRemove,
  maxDate,
  className = '',
  boldLabel = false,
  error,
  icon,
}: {
  id: string;
  control: Control<T>;
  name: Path<T>;
  label?: string;
  inline?: boolean;
  showTimeSelect?: boolean;
  daysToRemove: Date[];
  maxDate?: Date;
  className?: string;
  boldLabel?: boolean;
  error?: string;
  icon?: boolean;
}) => {
  const baseClassNames =
    'h-10 w-full flex items-center text-base text-color rounded-xl px-4 border-2 bg-color cursor-pointer pr-8';

  type CustomInputProps = {
    value?: string;
    onClick?: () => void;
  };

  const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(({ value, onClick }: CustomInputProps, ref) => {
    return (
      <button type="button" className={`${baseClassNames} ${className}`} onClick={onClick} ref={ref}>
        {value}
      </button>
    );
  });

  const filterDate = (date: Date) => {
    if (!daysToRemove) {
      return false;
    }

    const isDateDisabled = daysToRemove.some((disabledDate) => {
      const dateParsed = new Date(disabledDate);
      return date.getMonth() === dateParsed.getMonth() && date.getDate() === dateParsed.getDate();
    });

    return !isDateDisabled;
  };

  CustomInput.displayName = 'DatePickerInput';

  return (
    <div id={id} className={`relative flex w-full gap-2 ${inline ? 'flex-row items-center' : 'flex-col'}`}>
      {label && <div className={`ml-2 text-base ${boldLabel ? 'font-bold ' : ''}`}>{label}</div>}
      <div className="relative flex w-full flex-col">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ReactDatePicker
              filterDate={filterDate}
              showTimeSelect={showTimeSelect}
              selected={value}
              onChange={onChange}
              maxDate={maxDate}
              minDate={new Date()}
              customInput={<CustomInput />}
              dateFormat={showTimeSelect ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
              calendarStartDay={1}
              showIcon={icon}
              toggleCalendarOnIconClick={icon}
              icon={
                <MdOutlineCalendarMonth className="text-color absolute right-2 top-[20px] -translate-y-1/2 cursor-pointer text-xl" />
              }
            />
          )}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};

export default FilteredDatePicker;
