import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationHandler } from '@epcbuilder/lib/components/Layout';
import { ReferrerCookieHandler } from '@epcbuilder/lib/components/ReferrerCookieHandler';
import NotFoundPage from '@/app/404';
import AdminsPage from '@/app/admins';
import AssessorPage from '@/app/assessor';
import AssessorsPage from '@/app/assessors';
import LoginPage from '@/app/login';
import PropertiesPage from '@/app/properties';
import PropertyPage from '@/app/property';
import ReportPage from '@/app/report';
import UsersPage from '@/app/users';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ReferrerCookieHandler>
        <NavigationHandler>
          <Routes>
            <Route path="/">
              <Route index element={<LoginPage />} />
              <Route path="admins" element={<AdminsPage />} />
              <Route path="assessors" element={<AssessorsPage />} />
              <Route index path="assessor/:id" element={<AssessorPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="properties/">
                <Route index element={<PropertiesPage />} />
                <Route path=":id/">
                  <Route index element={<PropertyPage />} />
                  <Route path="report" element={<ReportPage />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </NavigationHandler>
      </ReferrerCookieHandler>
    </BrowserRouter>
  );
};

export default AppRoutes;
