import React, { MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  deleteReportPropertyOverviewFloor1Plan,
  deleteReportPropertyOverviewFloor2Plan,
  putReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloor2Plan,
} from '@/network/report';
import { PropertyOverviewImage } from '../helpers';
import { Bottom, Page, Top } from '../ReportLayout';

const ReportPageExtraFloorPlans = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  return (
    <Page refs={refs} index={3}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Property Overview</h1>
      </Top>
      <div className="mx-[64px] mt-[110px] flex flex-col gap-8">
        <div className="flex flex-1 flex-col gap-2">
          <p className="ml-4 text-lg font-bold">First Floor</p>
          <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
            <PropertyOverviewImage
              savingReport={savingReport}
              title="First Floor"
              image={jobReport?.propertyOverview.floor1plan}
              callback={putReportPropertyOverviewFloor1Plan}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
              onDelete={deleteReportPropertyOverviewFloor1Plan}
              showDeleteButton={!!jobReport?.propertyOverview.floor1plan}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <p className="ml-4 text-lg font-bold">Second Floor</p>
          <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
            <PropertyOverviewImage
              savingReport={savingReport}
              title="Second Floor"
              image={jobReport?.propertyOverview.floor2plan}
              callback={putReportPropertyOverviewFloor2Plan}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
              onDelete={deleteReportPropertyOverviewFloor2Plan}
              showDeleteButton={!!jobReport?.propertyOverview.floor2plan}
            />
          </div>
        </div>
        <Bottom title="Property Overview" />
      </div>
    </Page>
  );
};

export default ReportPageExtraFloorPlans;
