import React from 'react';

const PropertiesHeader = () => {
  return (
    <div className="border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-6">
      <div className="flex size-full min-h-12 flex-row gap-2">
        <div className="flex flex-1 flex-row gap-2 p-2">
          <div className={`mr-4 min-w-fit flex-1`}>
            <p className="flex h-full w-28 flex-row items-center gap-4">Owner</p>
          </div>
          <div className={`mr-4 min-w-fit flex-1`}>
            <p className="flex h-full w-28 flex-row items-center gap-4">Address Line 1</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 2xl:flex`}>
            <div className="flex h-full w-28 flex-row items-center gap-4">Address Line 2</div>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 2xl:flex`}>
            <p className="flex h-full w-28 flex-row items-center gap-4">City</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
            <p className="flex h-full w-20 flex-row items-center gap-4">Postcode</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
            <p className="flex h-full w-16 flex-row items-center gap-4">Current EPC</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 xl:flex`}>
            <p className="flex h-full w-16 flex-row items-center gap-4">Potential EPC</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 sm:flex`}>
            <p className="w-30 flex h-full flex-row items-center gap-4">Job Status</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
            <p className="flex h-full w-24 flex-row items-center gap-4">Last Updated</p>
          </div>
        </div>
      </div>
      <div className="w-24" />
    </div>
  );
};

export default PropertiesHeader;
