import React, { MutableRefObject, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, Modal, TextInput } from '@epcbuilder/lib/components';
import { Property } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import { KeyedMutator } from 'swr';
import { PropertyAddressRequest } from '@/models/properties';
import { putPropertyAddress } from '@/network/properties';
import { Bottom, Page, Top } from './ReportLayout';

const PropertyAddressModal = ({
  onClose,
  property,
  refetchProperty,
}: {
  onClose: () => void;
  property: Property;
  refetchProperty: KeyedMutator<Property>;
}) => {
  const defaultValues = {
    addressLine1: property.addressLine1,
    addressLine2: property.addressLine2,
    city: property.city,
    postcode: property.postcode,
  };

  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PropertyAddressRequest>({ defaultValues });

  const onSubmit: SubmitHandler<PropertyAddressRequest> = async () => {
    try {
      await putPropertyAddress({ id: property.id, data: getValues() });
      await refetchProperty();
      toast.success('Property Address Updated', { toastId: 'address-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<PropertyAddressRequest>(setError, errors);
    }
  };

  return (
    <Modal id="address-modal" onClose={onClose}>
      <h1>Edit Address</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          id="addressLine1"
          {...register('addressLine1')}
          label="Address Line 1"
          error={errors.addressLine1?.message}
        />
        <TextInput
          id="addressLine2"
          {...register('addressLine2')}
          label="Address Line 2"
          error={errors.addressLine2?.message}
        />
        <TextInput id="city" {...register('city')} label="City" error={errors.city?.message} />
        <TextInput id="postcode" {...register('postcode')} label="Postcode" error={errors.postcode?.message} />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="address-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const ReportPage1 = ({
  refs,
  property,
  refetchProperty,
  savingReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  property: Property;
  refetchProperty: KeyedMutator<Property>;
  savingReport: boolean;
}) => {
  const [editPropertyAddressModal, setEditPropertyAddressModal] = useState<boolean>(false);

  return (
    <>
      <Page refs={refs} index={0} transparent={true}>
        <div className="absolute inset-0 -z-10">
          <img
            src="/images/pdf-background.png"
            alt="EPC Builder PIP Report Background"
            className="size-full object-cover"
          />
        </div>
        <Top dark={true} />
        <div className="flex flex-col items-center pt-[104px]">
          <div className="flex flex-col gap-4 px-[150px]">
            <div className="relative h-[72px] w-[339px]">
              <img src="/images/pdf-logo.png" alt="EPC Builder Logo" className="size-full object-contain" />
            </div>
            <div className="via-primary/60 h-1 w-full bg-gradient-to-r from-transparent to-transparent" />
          </div>
          <div className="relative z-10 mt-[240px] w-[400px]">
            <div className="absolute -inset-1 -z-10 flex flex-col">
              <div
                style={{ transform: 'translate3d(0,0,0)' }}
                className="from-primary-dark via-primary to-secondary-light w-full flex-1 rounded-t-[32px] bg-gradient-to-b blur-md"
              />
              <div
                style={{ transform: 'translate3d(0,0,0)' }}
                className="from-secondary-light via-secondary to-secondary-dark w-full flex-1 rounded-b-[32px] bg-gradient-to-b blur-md"
              />
            </div>
            <div className="bg-blue-light relative z-10 flex flex-col gap-4 rounded-[32px] p-4">
              <h1 className="from-primary via-dark to-dark bg-gradient-to-r bg-clip-text text-center font-sans text-3xl font-bold leading-normal text-transparent">
                Property Improvement
                <br />
                Plan
              </h1>
              <div className="bg-primary text-light relative flex flex-col gap-1 rounded-[20px] p-4 text-center">
                <p className="text-xl">{property.addressLine1}</p>
                <p className="text-xl">{property.addressLine2} </p>
                <p className="text-xl">{property.city}</p>
                <p className="text-xl">{property.postcode}</p>
                {!savingReport && (
                  <button
                    id="edit-address-button"
                    type="button"
                    onClick={() => setEditPropertyAddressModal(true)}
                    className="absolute -bottom-2 right-0 cursor-pointer p-2"
                  >
                    <MdOutlineDriveFileRenameOutline size={24} />
                  </button>
                )}
              </div>
            </div>
          </div>
          <p className="text-dark mt-[38px] text-center text-lg font-normal uppercase italic">
            Report created on: {format(new Date(), 'dd/MM/yyyy')}
          </p>
        </div>
        <Bottom />
      </Page>
      {editPropertyAddressModal && (
        <PropertyAddressModal
          onClose={() => setEditPropertyAddressModal(false)}
          property={property}
          refetchProperty={refetchProperty}
        />
      )}
    </>
  );
};

export default ReportPage1;
