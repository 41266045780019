/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MutableRefObject, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdDelete, MdOutlineAdd, MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button, NumberInput, Select, TextInput } from '@epcbuilder/lib/components';
import Modal, { DeleteModal } from '@epcbuilder/lib/components/Modal';
import useImprovementCostCalculation from '@epcbuilder/lib/hooks/useImprovementCostCalculation';
import useImprovementQuickSelect from '@epcbuilder/lib/hooks/useImprovementQuickSelect';
import { ImprovementItem, JobImprovement, JobImprovementItem } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { getOptionByValue } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyedMutator } from 'swr';
import * as yup from 'yup';
import { JobReport } from '@/models/job';
import {
  deleteImprovementItem,
  postImprovementItem,
  putImprovementEpc,
  updateImprovementItem,
} from '@/network/improvements';
import { postJobImprovement } from '@/network/jobs';
import ImprovementQuickSelect from '../improvements/ImprovementQuickSelect';
import { Bottom, Page, Top } from './ReportLayout';

const IMPROVEMENT_COUNT = 3;
const TABLE_ROWS = 7;

const EditImprovementItem = ({
  improvement,
  itemIndex,
  refetchJobReport,
}: {
  improvement: JobImprovement;
  itemIndex: number;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const improvementItem = improvement.items[itemIndex];

  return (
    <>
      <div className="flex flex-row items-center justify-between gap-2">
        <p className="dark:text-dark text-xs font-bold leading-none">{improvementItem?.itemText || ''}</p>
        <div className="flex flex-row items-center gap-4">
          <p className="dark:text-dark text-xs font-bold leading-none">
            {improvementItem?.itemCost && `£${improvementItem?.itemCost.toFixed(2)}`}
          </p>
          {improvementItem?.itemText && (
            <>
              <button
                type="button"
                onClick={() => setShowEditModal(true)}
                className="cursor-point dark:text-dark item-center flex size-full justify-center"
              >
                <MdOutlineDriveFileRenameOutline size={12} />
              </button>
              <button
                type="button"
                onClick={() => setShowDeleteModal(true)}
                className="flex size-full cursor-pointer items-center justify-center"
                id={`improvement-${itemIndex}-remove-button`}
              >
                <MdDelete size={12} className="text-error" />
              </button>
            </>
          )}
        </div>
      </div>
      {showEditModal && (
        <EditImprovementItemModal
          improvementItem={improvementItem}
          onClose={() => setShowEditModal(false)}
          refetchJobReport={refetchJobReport}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          value={improvementItem?.itemText || ''}
          onClose={() => setShowDeleteModal(false)}
          callback={async () => {
            await deleteImprovementItem({ id: improvement.id, itemId: improvementItem.id });
            await refetchJobReport();
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

const EditImprovementItemModal = ({
  improvementItem,
  refetchJobReport,
  onClose,
}: {
  improvementItem: JobImprovementItem;
  refetchJobReport: KeyedMutator<JobReport>;
  onClose: () => void;
}) => {
  const defaultValues = {
    itemText: improvementItem.itemText ?? '',
    itemCost: improvementItem.itemCost ?? 0,
  };

  const availableQuickSelectTypes = useImprovementQuickSelect();
  const itemQuickAddModel = availableQuickSelectTypes.find(
    (x) => x.key.toLowerCase() == defaultValues.itemText?.toLowerCase()
  );
  const itemTextDisabled = (itemQuickAddModel && !itemQuickAddModel?.textEditable) ?? false;
  const itemCostDisabled = (itemQuickAddModel && !itemQuickAddModel?.costEditable) ?? false;

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ImprovementItem>({
    defaultValues: defaultValues,
    resolver: yupResolver(improvementItemSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ImprovementItem> = async (data) => {
    try {
      await updateImprovementItem({ id: improvementItem?.id, data });
      await refetchJobReport();
      toast.success('Improvement item edited', { toastId: 'improvement-item-edit-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ImprovementItem>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <p>Edit improvement item</p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          disabled={itemTextDisabled}
          id="itemText"
          {...register('itemText')}
          label="Item"
          error={errors.itemText?.message}
        />
        <NumberInput
          disabled={itemCostDisabled}
          id="itemCost"
          {...register('itemCost', {
            valueAsNumber: true,
          })}
          label="Cost"
          error={errors.itemCost?.message}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const EditImprovementItemsModal = ({
  improvement,
  onClose,
  refetchJobReport,
}: {
  improvement: JobImprovement;
  onClose: () => void;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  const [showAddImprovementItem, setShowAddImprovementItem] = useState<boolean>(false);
  return (
    <>
      <Modal onClose={onClose}>
        <div className="flex flex-col gap-2">
          <div className="bg-neutral-light relative flex h-[153px] flex-col gap-2 rounded-[16px] px-4 py-3">
            {Array.from({ length: TABLE_ROWS }).map((_, itemIndex) => (
              <EditImprovementItem
                key={`improvement-item-${itemIndex}`}
                improvement={improvement}
                itemIndex={itemIndex}
                refetchJobReport={refetchJobReport}
              />
            ))}
          </div>
          <Button onClick={() => setShowAddImprovementItem(true)}>Add New</Button>
          <Button style="secondary" onClick={onClose}>
            Save
          </Button>
        </div>
      </Modal>
      {showAddImprovementItem && (
        <AddImprovementItem
          improvement={improvement}
          onClose={() => setShowAddImprovementItem(false)}
          refetchJobReport={refetchJobReport}
          itemCost={0}
          itemText={''}
        />
      )}
    </>
  );
};

const improvementItemSchema = yup.object().shape({
  itemText: yup.string().required('Item must not be empty'),
  itemCost: yup.number().required('Cost must not be empty'),
});

const AddImprovementItem = ({
  improvement,
  onClose,
  refetchJobReport,
  itemText,
  itemCost,
}: {
  improvement: JobImprovement;
  onClose: () => void;
  refetchJobReport: KeyedMutator<JobReport>;
  itemText: string | undefined;
  itemCost: number;
}) => {
  const defaultValues = {
    itemText: itemText ?? '',
    itemCost: itemCost ?? 0,
  };

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ImprovementItem>({
    defaultValues,
    resolver: yupResolver(improvementItemSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ImprovementItem> = async (data) => {
    try {
      await postImprovementItem({ id: improvement?.id, data });
      await refetchJobReport();
      toast.success('Improvement item added', { toastId: 'improvement-item-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<ImprovementItem>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const availableQuickSelectTypes = useImprovementQuickSelect();
  const itemQuickAddModel = availableQuickSelectTypes.find((x) => x.key.toLowerCase() == itemText?.toLowerCase());
  const itemTextDisabled = (itemQuickAddModel && !itemQuickAddModel?.textEditable) ?? false;
  const itemCostDisabled = (itemQuickAddModel && !itemQuickAddModel?.costEditable) ?? false;

  return (
    <Modal onClose={onClose}>
      <p>Add new improvement item</p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput
          disabled={itemTextDisabled}
          id="itemText"
          {...register('itemText')}
          label="Item"
          error={errors.itemText?.message}
        />
        <NumberInput
          disabled={itemCostDisabled}
          id="itemCost"
          {...register('itemCost', {
            valueAsNumber: true,
          })}
          label="Cost"
          error={errors.itemCost?.message}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const EditNewEpcModal = ({
  improvement,
  onClose,
  refetchJobReport,
}: {
  improvement: JobImprovement;
  onClose: () => void;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  const defaultValues = {
    newEpc: '',
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ newEpc: string }>({ defaultValues });

  const onSubmit: SubmitHandler<{ newEpc: string }> = async (data) => {
    try {
      const optionLabel = getOptionByValue(epcRatingOptions, data.newEpc)?.label;
      if (!optionLabel) return;

      await putImprovementEpc({ id: improvement?.id, newEpc: optionLabel });
      await refetchJobReport();
      toast.success('New EPC Rating updated.');
      onClose();
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'PasHub ID must be set to complete survey.':
          toast.error('PasHub ID must be entered before a survey can be marked as complete.');
          break;
        case 'Property Rating could not be found':
          toast.error('Property Rating details must be entered before a survey can be marked as complete.');
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <h1>New EPC rating</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <Select
          control={control}
          id="newEpc"
          name="newEpc"
          title="select new epc rating"
          label="New EPC Rating"
          placeholder=""
          options={epcRatingOptions}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const ImprovementItemRow = ({ improvement, itemIndex }: { improvement: JobImprovement; itemIndex: number }) => {
  const improvementItem = improvement.items[itemIndex];

  return (
    <div
      className={`flex flex-row items-center justify-between gap-2 ${improvementItem?.improvementItemType?.isDiscount ? 'text-[#008080]' : ''}`}
    >
      <p className="text-xs font-bold leading-none" id={`improvement-item-text-${itemIndex}`}>
        {improvementItem?.itemText || ''}
      </p>
      <div className="flex flex-row items-center gap-4" id={`improvement-item-cost-${itemIndex}`}>
        <p className="text-xs font-bold leading-none">
          {improvementItem?.improvementItemType?.isDiscount && '- '}
          {improvementItem?.itemCost && `£${improvementItem?.itemCost.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
};

const ImprovementTable = ({
  jobReport,
  jobImprovements,
  value,
  index,
  refetchJobReport,
  savingReport,
}: {
  jobReport?: JobReport;
  jobImprovements: JobImprovement[];
  value: number;
  index: number;
  refetchJobReport: KeyedMutator<JobReport>;
  savingReport: boolean;
}) => {
  const improvement = jobImprovements[index];

  const [showEditItemsModal, setShowEditItemsModal] = useState<boolean>(false);
  const [showEditNewEpcModal, setShowEditNewEpcModal] = useState<boolean>(false);

  const [showAddImprovementItem, setShowAddImprovementItem] = useState<boolean>(false);
  const [improvementItem, setImprovementItem] = useState<ImprovementItem>();

  if (improvement) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const improvementCost = useImprovementCostCalculation(improvement.items);
    return (
      <>
        {showAddImprovementItem && (
          <AddImprovementItem
            improvement={improvement}
            onClose={() => setShowAddImprovementItem(false)}
            refetchJobReport={refetchJobReport}
            itemCost={improvementItem?.itemCost ?? 0}
            itemText={improvementItem?.itemText == 'Improvement Item' ? '' : improvementItem?.itemText ?? ''}
          />
        )}

        <div className="bg-light-dark relative flex h-[185px] w-full flex-row items-center gap-4 rounded-[16px] p-4 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
          {!savingReport && (
            <>
              <button type="button" className="absolute left-0 top-0 flex cursor-pointer p-2">
                <MdOutlineDriveFileRenameOutline
                  onClick={() => setShowEditItemsModal(true)}
                  size={24}
                  id={`item-edit-${value}`}
                />
                <ImprovementQuickSelect
                  improvement={improvement}
                  setImprovementItem={setImprovementItem}
                  setShowAddImprovementItem={setShowAddImprovementItem}
                  refetchJobReport={refetchJobReport}
                  selectType="Arrow"
                />
              </button>
            </>
          )}
          <div className="bg-blue flex size-10 items-center justify-center rounded-full">
            <p className="text-xl font-bold">{value}</p>
          </div>
          <div className="bg-neutral-light relative flex h-full flex-1 flex-col gap-2 rounded-[16px] px-4 py-3">
            {Array.from({ length: TABLE_ROWS }).map((_, itemIndex) => (
              <ImprovementItemRow
                key={`improvement-item-${itemIndex}`}
                improvement={improvement}
                itemIndex={itemIndex}
              />
            ))}
          </div>
          <div className="flex h-full flex-col gap-4">
            <div className="bg-blue relative flex w-[140px] flex-1 flex-col items-center justify-center gap-1 rounded-[16px]">
              <p className="text-sm font-bold">New EPC Rating</p>
              <p className="font-bold" id={`new-epc-rating-${value}`}>
                {improvement.newEpc || 'Unknown'}
              </p>
              {!savingReport && (
                <button
                  type="button"
                  onClick={() => setShowEditNewEpcModal(true)}
                  className="absolute -bottom-2 right-0 cursor-pointer p-2"
                  id={`new-epc-edit-${value}`}
                >
                  <MdOutlineDriveFileRenameOutline size={24} />
                </button>
              )}
            </div>
            <div className="bg-blue flex w-[140px] flex-1 flex-col items-center justify-center gap-1 rounded-[16px]">
              <p className="text-sm font-bold">Total Cost</p>
              <p className="font-bold" id={`total-cost-${value}`}>{`£${improvementCost.toFixed(2)}`}</p>
            </div>
          </div>
        </div>
        {showEditItemsModal && (
          <EditImprovementItemsModal
            improvement={improvement}
            onClose={() => setShowEditItemsModal(false)}
            refetchJobReport={refetchJobReport}
          />
        )}
        {showEditNewEpcModal && (
          <EditNewEpcModal
            improvement={improvement}
            onClose={() => setShowEditNewEpcModal(false)}
            refetchJobReport={refetchJobReport}
          />
        )}
      </>
    );
  }

  return (
    <button
      key={index}
      type="button"
      onClick={async () => {
        await postJobImprovement({ id: jobReport?.jobId });
        await refetchJobReport();
      }}
      className={`${
        savingReport && 'hidden'
      } bg-light-dark flex h-[185px] w-full flex-row items-center justify-center gap-4 rounded-[16px] p-4 shadow-[0_5px_5px_rgba(38,70,83,0.1)]`}
    >
      <MdOutlineAdd size={64} />
    </button>
  );
};

const ReportPage7 = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  return (
    <Page refs={refs} index={7}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Quotation & Payment Options</h1>
      </Top>
      <div className="mx-8 mt-20 flex flex-col gap-6">
        <div className="bg-primary/10 mx-16 flex h-8 items-center justify-center rounded-[8px] p-2">
          <p className="text-center text-xs font-medium">Your upgrade package options and cost breakdown</p>
        </div>
        <div className="flex flex-col gap-2">
          {jobReport?.improvements &&
            Array.from({ length: IMPROVEMENT_COUNT }, (_, index) => index + 1).map((value, index) => (
              <ImprovementTable
                key={`improvement-${index}`}
                jobReport={jobReport}
                jobImprovements={jobReport?.improvements}
                value={value}
                index={index}
                refetchJobReport={refetchJobReport}
                savingReport={savingReport}
              />
            ))}
          <div className="flex w-full flex-row items-center justify-between px-3">
            <p className="text-primary text-xs font-bold uppercase">
              All measures and costs are subject to a technical survey
            </p>
            <p className="text-primary text-xs font-bold uppercase">*VAT exempt</p>
          </div>
        </div>
      </div>
      <Bottom title="Quotation & Payment Options" />
    </Page>
  );
};

export default ReportPage7;
