import { Property } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getProperty } from '@/network/properties';

const useProperty = ({ id }: { id: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Property>(
    `property-data-${id}`,
    async () => await getProperty({ id })
  );

  return {
    property: data,
    error,
    mutate,
    isLoading,
  };
};

export default useProperty;
