import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, TextInput } from '@epcbuilder/lib/components';
import Modal from '@epcbuilder/lib/components/Modal';
import { User } from '@epcbuilder/lib/models/user';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EditAdmin } from '@/models/properties';
import { putAdmin } from '@/network/admins';

const editAdminSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
});

const EditAdminModal = ({ admin, onClose }: { admin: User; onClose: () => void }) => {
  const defaultValues: EditAdmin = {
    email: admin.email || '',
    firstName: admin.firstName || '',
    lastName: admin.lastName || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<EditAdmin>({
    defaultValues,
    resolver: yupResolver(editAdminSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<EditAdmin> = async (data) => {
    try {
      await putAdmin({
        id: admin.id,
        data: { email: data.email.trim(), firstName: data.firstName, lastName: data.lastName },
      });
      toast.success('Admin successfully updated', { toastId: 'edit-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<EditAdmin>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="edit-modal" onClose={onClose}>
      <h1>Edit Admin</h1>
      <form className="mt-4 flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          id="email"
          name="email"
          title="Your email address"
          placeholder="Email"
          error={errors.email?.message}
        />
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="Your first name"
          placeholder="First name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="Your last name"
          placeholder="Last name"
          error={errors.lastName?.message}
        />
        <div className="flex flex-row gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="edit-submit" loading={isSubmitting} type="submit">
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditAdminModal;
