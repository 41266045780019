import React, { useCallback, useState } from 'react';
import { MdDelete, MdEdit, MdPassword, MdRestoreFromTrash } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IconButton } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { User } from '@epcbuilder/lib/models/user';
import { format } from 'date-fns';
import { deleteUser, restoreUser } from '@/network/users';
import ChangePasswordModal from './ChangePasswordModal';
import EditUserModal from './EditUserModal';
import RestoreModal from './RestoreModal';

const UsersRow = ({ user, mutate }: { user: User; mutate: () => void }) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteUser({ id: user.id });
      toast.success('User successfully deleted', { toastId: 'delete-success' });
      mutate();
    } catch (error: unknown) {
      toast.error('There was a problem deleting User. Please try again.');
    }
  }, [mutate, user]);

  const handleRestoreUser = useCallback(async () => {
    try {
      await restoreUser({ id: user.id });
      toast.success('User successfully restored', { toastId: 'restore-success' });
      mutate();
    } catch (error: unknown) {
      toast.error('There was a problem restoring User. Please try again.');
    }
  }, [mutate, user]);

  return (
    <>
      <div
        id={user.id}
        className="border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div className="flex size-full min-h-20 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2">
            <div className={`mr-4 min-w-fit flex-1`}>
              <p
                id={`${user.id}-fullName`}
                className="w-30 flex h-full flex-row items-center gap-4 sm:w-40"
              >{`${user.firstName} ${user.lastName}`}</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
              <p
                id={`${user.id}-email`}
                className="flex h-full w-48 flex-row items-center gap-4 break-all xl:w-64 xl:break-normal"
              >
                {user.email}
              </p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
              <p id={`${user.id}-createdOn`} className="flex h-full w-24 flex-row items-center gap-4">
                {format(new Date(user.createdOn), 'dd/MM/yyyy')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-40 min-w-fit flex-row items-center justify-center gap-4">
          {user.isDeleted ? (
            <IconButton id={`${user.id}-restore`} style="delete" onClick={() => setShowRestoreModal(true)}>
              <MdRestoreFromTrash size={20} />
            </IconButton>
          ) : (
            <IconButton id={`${user.id}-delete`} style="delete" onClick={() => setShowDeleteModal(true)}>
              <MdDelete size={20} />
            </IconButton>
          )}
          <IconButton id={`${user.id}-password`} style="secondary" onClick={() => setShowChangePasswordModal(true)}>
            <MdPassword size={20} />
          </IconButton>
          <IconButton id={`${user.id}-edit`} style="secondary" onClick={() => setShowEditModal(true)}>
            <MdEdit size={20} />
          </IconButton>
          <IconButton
            id={`${user.id}-properties`}
            onClick={() => navigate(`/properties?search=${`${user.firstName} ${user.lastName}`}`)}
          >
            <PiHouseSimpleBold size={20} />
          </IconButton>
        </div>
      </div>
      {showEditModal && (
        <EditUserModal
          user={user}
          onClose={() => {
            setShowEditModal(false);
            mutate();
          }}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal id={user.id} onClose={() => setShowChangePasswordModal(false)} />
      )}
      {showDeleteModal && (
        <DeleteModal
          callback={handleDeleteUser}
          onClose={() => setShowDeleteModal(false)}
          value={`${user.firstName} ${user.lastName}`}
        />
      )}
      {showRestoreModal && (
        <RestoreModal
          callback={handleRestoreUser}
          onClose={() => setShowRestoreModal(false)}
          value={`${user.firstName} ${user.lastName}`}
        />
      )}
    </>
  );
};

export default UsersRow;
