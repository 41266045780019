import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';

const Pagination = <T extends FieldValues>({
  control,
  name,
  totalPages,
}: {
  control: Control<T>;
  name: Path<T>;
  totalPages: number;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentPage = value;
        const pagesToShow = 1; // Number of pages to show before/after the current page

        const renderPageNumbers = () => {
          const pageNumbers = [];

          for (let i = 1; i <= totalPages; i++) {
            if (
              i === 1 ||
              i === totalPages ||
              i === currentPage ||
              (i >= currentPage - pagesToShow && i <= currentPage + pagesToShow)
            ) {
              pageNumbers.push(i);
            } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
              pageNumbers.push('...');
            }
          }

          return pageNumbers.map((pageNumber, index) => (
            <button
              type="button"
              key={index}
              id={`pagination-${pageNumber}${pageNumber === currentPage ? '-active' : ''}`}
              className={`mx-1 cursor-pointer rounded p-2 ${
                pageNumber === '...' ? 'text-neutral-dark' : pageNumber === currentPage && 'text-color font-bold'
              }`}
              onClick={() => pageNumber !== '...' && handleChangePage(pageNumber)}
            >
              {pageNumber}
            </button>
          ));
        };

        const handleChangePage = (pageNumber: string | number) => {
          if (pageNumber !== '...') {
            onChange(pageNumber); // Update the currentPage in the form
          }
        };

        return (
          <div className="flex flex-row items-center">
            {currentPage > 2 && (
              <button
                type="button"
                id="pagination-first"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(1)}
              >
                <MdOutlineKeyboardDoubleArrowLeft />
              </button>
            )}
            {currentPage > 1 && (
              <button
                type="button"
                id="pagination-previous"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(currentPage - 1)}
              >
                <MdOutlineKeyboardArrowLeft />
              </button>
            )}
            {renderPageNumbers()}
            {currentPage < totalPages && (
              <button
                type="button"
                id="pagination-next"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(currentPage + 1)}
              >
                <MdOutlineKeyboardArrowRight />
              </button>
            )}
            {currentPage < totalPages - 1 && (
              <button
                type="button"
                id="pagination-last"
                className="text-color mx-1 cursor-pointer rounded p-2"
                onClick={() => handleChangePage(totalPages)}
              >
                <MdOutlineKeyboardDoubleArrowRight />
              </button>
            )}
          </div>
        );
      }}
    />
  );
};

export default Pagination;
