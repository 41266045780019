import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { UsersTable } from '@/components/users';
import withAuth from '@/hoc/withAuth';

const Users = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder - Admin - Users</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Users">
        <UsersTable />
      </Layout>
    </>
  );
};

export default withAuth(Users);
