import React, { MutableRefObject } from 'react';
import { Bottom, Page, TableContents, Top } from './ReportLayout';

const ReportPage2 = ({ refs }: { refs: MutableRefObject<(HTMLDivElement | null)[]> }) => {
  return (
    <Page refs={refs} index={1}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Table of contents</h1>
      </Top>
      <div className="mt-36 flex flex-col gap-[80px]">
        <TableContents title="Property Overview" />
        <TableContents title="EPC Summary" />
        <TableContents title="Grants & Funding" />
        <TableContents title="Quotation & Payment Options" />
        {/*<TableContents title="Ways to Pay" />*/}
        <TableContents title="Next Steps" />
      </div>
      <Bottom title="Table of Contents" />
    </Page>
  );
};

export default ReportPage2;
