import { User } from '@epcbuilder/lib/models/user';
import useSWR from 'swr';
import { getUsers } from '@/network/users';

const useUsers = () => {
  const { data, error, isLoading, mutate } = useSWR<User[]>('users-data', async () => await getUsers(), {
    keepPreviousData: true,
  });

  return {
    users: data,
    error,
    mutate,
    isLoading,
  };
};

export default useUsers;
