import React from 'react';

const AdminHeader = () => {
  return (
    <div className="border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-4">
      <div className="flex size-full min-h-12 flex-row gap-2">
        <div className="flex flex-1 flex-row gap-2 p-2">
          <div className={`mr-4 min-w-fit flex-1`}>
            <p className="flex h-full w-40 flex-row items-center gap-4">Name</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
            <p className="flex h-full w-48 flex-row items-center gap-4 xl:w-64">Email</p>
          </div>
          <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
            <p className="flex h-full w-24 flex-row items-center gap-4">Created</p>
          </div>
        </div>
      </div>
      <div className="w-44"></div>
    </div>
  );
};

export default AdminHeader;
