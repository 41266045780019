import React, { MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  putReportPropertyOverviewHeating,
  putReportPropertyOverviewHeatingControls,
  putReportPropertyOverviewHeatingControlsDescription,
  putReportPropertyOverviewHeatingDescription,
  putReportPropertyOverviewLighting,
  putReportPropertyOverviewLightingFittings,
  putReportPropertyOverviewLightingLowEnergy,
} from '@/network/report';
import { PropertyOverviewImage, TextField } from './helpers';
import { Bottom, Page, Top } from './ReportLayout';

const ReportPage5 = ({
  refs,
  savingReport,
  jobReport,
  refetchJobReport,
}: {
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  savingReport: boolean;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
}) => {
  return (
    <Page refs={refs} index={5}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Property Overview</h1>
        <div className="bg-primary/10 ml-2 rounded-[4px] px-2 py-1">
          <p className="text-lg font-bold">Heating and Lighting</p>
        </div>
      </Top>
      <div className="mt-32 flex flex-col gap-6">
        <div className="relative mr-[72px] flex">
          <div className="bg-primary absolute -right-2 mt-1 h-[174px] w-6 rounded-r-[18px]" />

          <div className="bg-light-dark relative flex h-[180px] flex-1 flex-row gap-4 rounded-r-[18px] py-3 pl-[88px] shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
            <TextField
              name="Heating"
              title="Property Overview Heating"
              placeholder="Heating details..."
              savingReport={savingReport}
              defaultValue={jobReport?.propertyOverview.heating?.description}
              callback={putReportPropertyOverviewHeatingDescription}
              className="bg-neutral-light relative h-full w-[210px] rounded-[18px]"
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
            />
            <PropertyOverviewImage
              savingReport={savingReport}
              title="Heating"
              image={jobReport?.propertyOverview.heating?.photo}
              callback={putReportPropertyOverviewHeating}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              className="bg-blue-light relative h-full w-[150px] overflow-hidden rounded-[18px]"
            />
            <p className="-ml-1 text-center text-[34px] font-extrabold uppercase leading-none [writing-mode:vertical-lr]">
              Heating
            </p>
          </div>
        </div>
        <div className="relative ml-[72px] flex">
          <div className="bg-primary absolute -left-2 mt-1 h-[174px] w-6 rounded-l-[18px]" />
          <div className="bg-light-dark relative flex h-[180px] flex-1 flex-row gap-4 rounded-l-[18px] py-3 pl-3 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
            <div className="-mr-1 flex flex-row gap-1">
              <p className="rotate-180 text-center text-[34px] font-extrabold uppercase leading-none [writing-mode:vertical-lr]">
                Heating
              </p>
              <p className="rotate-180 text-center text-[27px] font-extrabold uppercase leading-none [writing-mode:vertical-lr]">
                Controls
              </p>
            </div>
            <PropertyOverviewImage
              savingReport={savingReport}
              title="Heating Controls"
              image={jobReport?.propertyOverview.heating?.controllerPhoto}
              callback={putReportPropertyOverviewHeatingControls}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              className="bg-blue-light relative h-full w-[150px] overflow-hidden rounded-[18px]"
            />
            <TextField
              name="Heating Controls"
              title="Property Overview Heating Controls"
              placeholder="Heating controls details..."
              savingReport={savingReport}
              defaultValue={jobReport?.propertyOverview.heating?.controllerDescription}
              callback={putReportPropertyOverviewHeatingControlsDescription}
              className="bg-neutral-light relative h-full w-[210px] rounded-[18px]"
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
            />
          </div>
        </div>
        <div className="relative mr-[72px] flex">
          <div className="bg-primary absolute -right-2 mt-1 h-[174px] w-6 rounded-r-[18px]" />
          <div className="bg-light-dark relative flex h-[180px] flex-1 flex-row gap-4 rounded-r-[18px] py-3 pl-[88px] shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
            <div className="flex w-[210px] flex-col gap-2">
              <div className="flex flex-col gap-1">
                <p className="font-bold">Total light fittings:</p>
                <TextField
                  name="Lighting Fittings"
                  title="Property Overview Lighting Fittings"
                  placeholder="Total fittings..."
                  savingReport={savingReport}
                  defaultValue={jobReport?.propertyOverview.lighting?.totalLightFittings}
                  callback={putReportPropertyOverviewLightingFittings}
                  className="bg-neutral-light relative h-11 w-full rounded-[12px]"
                  jobReport={jobReport}
                  refetchJobReport={refetchJobReport}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="font-bold">Low energy lights:</p>
                <TextField
                  name="Lighting Low Energy"
                  title="Property Overview Lighting Low Energy"
                  placeholder="Low Energy Lights..."
                  savingReport={savingReport}
                  defaultValue={jobReport?.propertyOverview.lighting?.lowEnergyLights}
                  callback={putReportPropertyOverviewLightingLowEnergy}
                  className="bg-neutral-light relative h-11 w-full rounded-[12px]"
                  jobReport={jobReport}
                  refetchJobReport={refetchJobReport}
                />
              </div>
            </div>
            <PropertyOverviewImage
              savingReport={savingReport}
              title="Lighting"
              image={jobReport?.propertyOverview.lighting?.photo}
              callback={putReportPropertyOverviewLighting}
              jobReport={jobReport}
              refetchJobReport={refetchJobReport}
              className="bg-blue-light relative h-full w-[150px] overflow-hidden rounded-[18px]"
            />
            <p className="-ml-1 text-center text-[32px] font-extrabold uppercase leading-none [writing-mode:vertical-lr]">
              Lighting
            </p>
          </div>
        </div>
      </div>
      <Bottom title="Property Overview - Heating and Lighting" />
    </Page>
  );
};

export default ReportPage5;
