import React, { useEffect, useMemo, useState } from 'react';
import { Absence } from '@epcbuilder/lib/models/assessors';
import { Booking } from '@epcbuilder/lib/models/booking';
import { BookingSlotTimeframe } from '@epcbuilder/lib/models/jobs';
import { addMonths, isSameDay } from 'date-fns';
import useAbsence from '@/hooks/absences/useAbsence';
import useAssessor from '@/hooks/assessors/useAssessor';
import useAssessorSurveyBooking from '@/hooks/surveys/useAssessorSurveyBooking';
import { isAmAbsence, isFullDayAbsence, isPmAbsence } from '@/utils/absenceUtils';

const BookedSlot = ({
  dayName,
  slot,
  onClick,
  isActive,
}: {
  dayName: string;
  slot: string;
  onClick: () => void;
  isActive: boolean;
}) => (
  <button
    id={`booked-slot=${dayName}-${slot}`}
    className={` bg-neutral-light dark:bg-dark-light mt-2 flex h-[100px] w-[4rem] flex-col items-center justify-center rounded-[12px] lg:h-[105px] lg:w-[6rem] ${
      isActive ? 'border-neutral-dark border-2' : 'hover:border-neutral-dark'
    }`}
    onClick={onClick}
    type="button"
  >
    <p className="text-xs font-bold leading-tight lg:text-base">Booked</p>
  </button>
);

const UnavailableSlot = ({ dayName, slot, onClick }: { dayName: string; slot: string; onClick: () => void }) => (
  <button
    id={`unavailable-slot=${dayName}-${slot}`}
    className="bg-secondary-light dark:bg-secondary hover:border-secondary-dark mt-2 flex h-[100px] w-[4rem] flex-col items-center justify-center rounded-[12px] hover:border-2 lg:h-[105px] lg:w-[6rem]"
    onClick={onClick}
    type="button"
  >
    <p className="text-xs font-bold leading-tight lg:text-base">Not</p>
    <p className="text-xs font-bold leading-tight lg:text-base">avail.</p>
  </button>
);

const AvailableSlot = ({
  dayName,
  onClick,
  startTime,
  endTime,
  slot,
}: {
  dayName: string;
  onClick: () => void;
  startTime: string;
  endTime: string;
  slot: string;
}) => (
  <button
    id={`available-slot=${dayName}-${slot}`}
    className="bg-blue dark:bg-primary hover:border-primary-lighter mt-2 flex h-[100px] w-[4rem] flex-col items-center justify-center rounded-[12px] hover:border-2 lg:h-[105px] lg:w-[6rem]"
    onClick={onClick}
    type="button"
  >
    <p className="text-xs font-bold leading-tight lg:text-base">{slot}</p>
    <p className="text-xs font-bold leading-tight lg:text-base">{startTime}</p>
    <p className="text-xs font-bold leading-tight lg:text-base">-</p>
    <p className="text-xs font-bold leading-tight lg:text-base">{endTime}</p>
  </button>
);

const FullDayUnavailableSlot = ({ dayName, onClick }: { dayName: string; onClick: () => void }) => (
  <button
    id={`unavailable-slot=${dayName}-full-day`}
    className=" bg-secondary-light dark:bg-secondary mt-2 flex h-[200px] w-[4rem] flex-col items-center justify-center rounded-[12px] bg-gray-400 hover:border-gray-500 lg:h-[220px] lg:w-[6rem] dark:bg-gray-700"
    onClick={onClick}
    type="button"
  >
    <p className="text-xs font-bold leading-tight lg:text-base">Not</p>
    <p className="text-xs font-bold leading-tight lg:text-base">avail.</p>
  </button>
);

const renderSlot = ({
  dayName,
  isBooked,
  isAbsent,
  isFullDayAbsent,
  isAvailable,
  handleClick,
  startTime,
  endTime,
  slot,
  isActive,
}: {
  dayName: string;
  isBooked: boolean;
  isAbsent: Absence | undefined;
  isFullDayAbsent: Absence | undefined;
  isAvailable: boolean;
  handleClick: () => void;
  startTime: string;
  endTime: string;
  slot: string;
  isActive?: boolean;
}) => {
  if (isFullDayAbsent) return <FullDayUnavailableSlot dayName={dayName} onClick={handleClick} />;
  if (isBooked) return <BookedSlot dayName={dayName} slot={slot} onClick={handleClick} isActive={!!isActive} />;
  if (isAbsent || !isAvailable) return <UnavailableSlot dayName={dayName} slot={slot} onClick={handleClick} />;
  return <AvailableSlot dayName={dayName} onClick={handleClick} startTime={startTime} endTime={endTime} slot={slot} />;
};

const BookingSlotCalendar = ({
  id,
  date,
  onAbsenceClick,
  onSlotClick,
  onBookingSelect,
}: {
  id: string;
  date: Date;
  onAbsenceClick: (absence: Absence) => void;
  onSlotClick: (date: Date, slot: number) => void;
  onBookingSelect: (booking: Booking | undefined) => void;
}) => {
  const FIRST_DATE = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }, []);
  const END_DATE = useMemo(() => addMonths(FIRST_DATE, 3), [FIRST_DATE]);
  const [start, setStart] = useState(FIRST_DATE);
  const [selectedBooking, setSelectedBooking] = useState<Booking | undefined>(undefined);

  useEffect(() => {
    setStart(FIRST_DATE);
  }, [FIRST_DATE]);

  const { absence } = useAbsence({ id, start, end: END_DATE });
  const { assessor } = useAssessor({ id });
  const { bookings } = useAssessorSurveyBooking({ id, start, end: END_DATE });

  const getWorkingHours = (dayName: string) =>
    assessor?.workingDays?.find((workingDay) => workingDay.dayName === dayName);

  const isBooked = (slotNumber: number) => {
    return bookings?.find((booking) => isSameDay(new Date(booking.surveyDate), date) && booking.slot === slotNumber);
  };

  const handleBookingClick = (booking: Booking | undefined) => {
    if (selectedBooking?.id === booking?.id) {
      setSelectedBooking(undefined);
      onBookingSelect(undefined);
    } else {
      setSelectedBooking(booking);
      onBookingSelect(booking);
    }
  };

  const renderSlots = () => {
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    const workingHours = getWorkingHours(dayName);
    const isAmAbsent = absence?.find((a) => isAmAbsence(a, date));
    const isPmAbsent = absence?.find((a) => isPmAbsence(a, date));
    const isFullDayAbsent = absence?.find((a) => isFullDayAbsence(a, date));

    if (!workingHours) {
      return (
        <div
          id={`no-working-hours-slot=${dayName}-full-day`}
          className="bg-neutral-lighter dark:bg-dark-lightest mt-2 flex h-[200px] w-[4rem] flex-col items-center justify-center rounded-[12px] lg:h-[220px] lg:w-[6rem]"
        >
          <p className="text-xs font-bold leading-tight lg:text-base">N/A</p>
        </div>
      );
    }

    const { startTime, endTime } = workingHours;
    const [startHour] = startTime.split(':').map(Number);
    const [endHour] = endTime.split(':').map(Number);

    return (
      <>
        {isFullDayAbsent ? (
          <FullDayUnavailableSlot dayName={dayName} onClick={() => onAbsenceClick(isFullDayAbsent)} />
        ) : (
          <>
            {startHour >= 12 ? (
              <div className="bg-neutral-lighter dark:bg-dark-lightest mt-2 flex h-[100px] w-[4rem] flex-col items-center justify-center rounded-[12px] lg:h-[105px] lg:w-[6rem]">
                <p className="text-xs font-bold leading-tight lg:text-base">N/A</p>
              </div>
            ) : (
              renderSlot({
                dayName,
                isBooked: !!isBooked(1),
                isAbsent: isAmAbsent,
                isFullDayAbsent,
                isAvailable: startHour < 12,
                handleClick: () => {
                  const booking = isBooked(1);
                  if (isAmAbsent) onAbsenceClick(isAmAbsent);
                  else if (booking) handleBookingClick(booking);
                  else onSlotClick(date, 1);
                },
                startTime: BookingSlotTimeframe.AmSlotStartHour,
                endTime: BookingSlotTimeframe.AmSlotEndHour,
                slot: 'AM',
                isActive: selectedBooking?.slot === 1 && isSameDay(new Date(selectedBooking.surveyDate), date),
              })
            )}
            {endHour <= 12 ? (
              <div className="bg-neutral-lighter dark:bg-dark-lightest mt-2 flex h-[100px] w-[4rem] flex-col items-center justify-center rounded-[12px] lg:h-[105px] lg:w-[6rem]">
                <p className="text-xs font-bold leading-tight lg:text-base">N/A</p>
              </div>
            ) : (
              renderSlot({
                dayName,
                isBooked: !!isBooked(2),
                isAbsent: isPmAbsent,
                isFullDayAbsent,
                isAvailable: endHour > 12,
                handleClick: () => {
                  const booking = isBooked(2);
                  if (isPmAbsent) onAbsenceClick(isPmAbsent);
                  else if (booking) handleBookingClick(booking);
                  else onSlotClick(date, 2);
                },
                startTime: BookingSlotTimeframe.PmSlotStartHour,
                endTime: BookingSlotTimeframe.PmSlotEndHour,
                slot: 'PM',
                isActive: selectedBooking?.slot === 2 && isSameDay(new Date(selectedBooking.surveyDate), date),
              })
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">{renderSlots()}</div>
      <div className="border-blue dark:border-primary-dark h-full border-r-[2px]" />
    </>
  );
};

export default BookingSlotCalendar;
