import {
  AbsenceFormData,
  AssessorData,
  AssessorFormInput,
  WorkingDay,
  WorkingDayForm,
} from '@epcbuilder/lib/models/assessors';
import { api, formatUtcDatetime } from '@epcbuilder/lib/utils';
import { DaysOfWeek } from '@epcbuilder/lib/utils/datetime';

export const getAllAssessors = async () => {
  return await api.get('/assessors');
};

export const putAssessor = async (data: AssessorFormInput) => {
  const defaultValues = {
    active: true,
    capacityAM: 1,
    capacityPM: 1,
    workingDays: [
      { dayName: 'Monday', startTime: '09:00:00', endTime: '17:00:00' },
      { dayName: 'Tuesday', startTime: '09:00:00', endTime: '17:00:00' },
      { dayName: 'Wednesday', startTime: '09:00:00', endTime: '17:00:00' },
      { dayName: 'Thursday', startTime: '09:00:00', endTime: '17:00:00' },
      { dayName: 'Friday', startTime: '09:00:00', endTime: '17:00:00' },
    ],
  };
  const assessorData: AssessorData = {
    ...data,
    ...defaultValues,
    email: data.email.trim(),
    workingDays: defaultValues.workingDays.map((day) => ({
      ...day,
    })),
  };
  return await api.put('/assessor', assessorData);
};

export const patchAssessor = async (data: AssessorFormInput) => {
  const assessorData: AssessorFormInput = {
    ...data,
    email: data.email.trim(),
  };
  return await api.patch('/assessor', assessorData);
};

export const getAssessor = async ({ id }: { id: string }) => {
  return await api.get(`/assessor/${id}`);
};

const transformFormDataToWorkingDays = (data: WorkingDayForm[], assessorId: string): WorkingDay[] => {
  const dataArray = Object.values(data);
  return dataArray.reduce((acc, day, index) => {
    if (day.startTime && day.endTime) {
      acc.push({
        assessorId,
        dayName: DaysOfWeek[index],
        startTime: `${day.startTime}:00`,
        endTime: `${day.endTime}:00`,
      });
    }
    return acc;
  }, [] as WorkingDay[]);
};

export const putWorkingHours = async (data: WorkingDayForm[], assessorId: string) => {
  const workingHoursData = transformFormDataToWorkingDays(data, assessorId);
  return await api.put('/assessor/working-hours', workingHoursData);
};

export const getAbsence = async ({ id, start, end }: { id: string; start: Date; end: Date }) => {
  return await api.get(
    `/assessor/${id}/absence?StartDate=${formatUtcDatetime(start)}&EndDate=${formatUtcDatetime(end)}`
  );
};

export const putAbsence = async (data: AbsenceFormData, assessorId: string) => {
  const { absenceDateStart, absenceDateEnd, slotStart, slotEnd } = data;

  const adjustDateTime = (date: Date, time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const startTime = slotStart === 'am' ? '09:00:00' : '13:00:00';
  const endTime = slotEnd === 'am' ? '12:00:00' : '17:00:00';

  const startDate = adjustDateTime(absenceDateStart, startTime);
  const endDate = adjustDateTime(absenceDateEnd, endTime);

  const formattedStartDate = startDate.toISOString().split('.')[0] + 'Z';
  const formattedEndDate = endDate.toISOString().split('.')[0] + 'Z';

  const formattedData = {
    ...data,
    assessorId,
    absenceDateStart: formattedStartDate,
    absenceDateEnd: formattedEndDate,
  };

  return await api.put('/assessor/absence', formattedData);
};

export const patchAbsence = async (data: AbsenceFormData, assessorId: string, id: string) => {
  const { absenceDateStart, absenceDateEnd, slotStart, slotEnd } = data;

  const adjustDateTime = (date: Date, time: string) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const startTime = slotStart === 'am' ? '09:00:00' : '13:00:00';
  const endTime = slotEnd === 'am' ? '12:00:00' : '17:00:00';

  const startDate = adjustDateTime(absenceDateStart, startTime);
  const endDate = adjustDateTime(absenceDateEnd, endTime);

  const formattedStartDate = startDate.toISOString().split('.')[0] + 'Z';
  const formattedEndDate = endDate.toISOString().split('.')[0] + 'Z';

  const formattedData = {
    ...data,
    assessorId,
    absenceDateStart: formattedStartDate,
    absenceDateEnd: formattedEndDate,
  };

  return await api.patch(`/assessor/absence/${id}`, formattedData);
};

export const deleteAbsence = async (id: string) => {
  return await api.delete(`/assessor/absence/${id}`);
};

export const getAssessorSurveyBooking = async ({ id, start, end }: { id: string; start: Date; end: Date }) => {
  return await api.get(
    `/assessor/${id}/bookings?startDate=${formatUtcDatetime(start)}&endDate=${formatUtcDatetime(end)}`
  );
};
