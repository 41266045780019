import React, { useCallback, useContext, useState } from 'react';
import { MdDelete, MdEdit, MdPassword } from 'react-icons/md';
import { toast } from 'react-toastify';
import { IconButton } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { AuthContext } from '@epcbuilder/lib/context/authContext';
import { User } from '@epcbuilder/lib/models/user';
import { format } from 'date-fns';
import { deleteAdmin } from '@/network/admins';
import ChangeAdminPasswordModal from './ChangeAdminPasswordModal';
import EditAdminModal from './EditAdminModal';

const AdminRow = ({ admin, mutate }: { admin: User; mutate: () => void }) => {
  const { state } = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);

  const handleDeleteAdmin = useCallback(async () => {
    try {
      await deleteAdmin({ id: admin.id });
      toast.success('Admin successfully deleted', { toastId: 'delete-success' });
      mutate();
    } catch (error: unknown) {
      toast.error('There was a problem deleting Admin. Please try again.');
    }
  }, [mutate, admin]);

  return (
    <>
      <div
        id={admin.id}
        className="border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-4"
      >
        <div className="flex size-full min-h-20 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2">
            <div className={`mr-4 min-w-fit flex-1`}>
              <p
                id={`${admin.id}-fullName`}
                className="flex h-full w-40 flex-row items-center gap-4"
              >{`${admin.firstName} ${admin.lastName}`}</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
              <p
                id={`${admin.id}-email`}
                className="flex h-full w-48 flex-row items-center gap-4 break-all xl:w-64 xl:break-normal"
              >
                {admin.email}
              </p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
              <p id={`${admin.id}-createdOn`} className="flex h-full w-24 flex-row items-center gap-4">
                {format(new Date(admin.createdOn), 'dd/MM/yyyy')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-40 min-w-fit flex-row items-center justify-end gap-4">
          {state.userId !== String(admin.id) && (
            <IconButton id={`${admin.id}-delete`} style="delete" onClick={() => setShowDeleteModal(true)}>
              <MdDelete size={20} />
            </IconButton>
          )}
          <IconButton id={`${admin.id}-password`} style="secondary" onClick={() => setShowChangePasswordModal(true)}>
            <MdPassword size={20} />
          </IconButton>
          <IconButton id={`${admin.id}-edit`} onClick={() => setShowEditModal(true)}>
            <MdEdit size={20} />
          </IconButton>
        </div>
      </div>
      {showEditModal && (
        <EditAdminModal
          admin={admin}
          onClose={() => {
            setShowEditModal(false);
            mutate();
          }}
        />
      )}
      {showChangePasswordModal && (
        <ChangeAdminPasswordModal id={admin.id} onClose={() => setShowChangePasswordModal(false)} />
      )}
      {showDeleteModal && (
        <DeleteModal
          callback={handleDeleteAdmin}
          onClose={() => setShowDeleteModal(false)}
          value={`${admin.firstName} ${admin.lastName}`}
        />
      )}
    </>
  );
};

export default AdminRow;
