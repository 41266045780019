import { api } from '@epcbuilder/lib/utils';

export const getJobReportPdf = async ({ id }: { id?: string }) => {
  return await api.getFile(`/job/${id}/report/pdf`, 'EPC Builder Report.pdf');
};

export const postJobReportPdf = async ({ id, data }: { id?: string; data: string[] }) => {
  return await api.post(`/job/${id}/report/pdf`, { data });
};

export const patchPashubId = async ({ id, pashubid }: { id: string; pashubid?: string }) => {
  return await api.patch(`/job/${id}/pashubid`, { pashubid });
};

export const postCreateJobSurvey = async ({ id }: { id: string }) => {
  return await api.post(`/job/${id}/survey`);
};

export const getJobSurvey = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/survey`);
};

export const getJobinstallation = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/installation`);
};

export const postJobGenerateReport = async ({ id }: { id?: string }) => {
  return await api.post(`/job/${id}/report`);
};

export const getJobReport = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/report`);
};

export const getJobImprovements = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/improvement`);
};

export const postJobImprovement = async ({ id }: { id?: string }) => {
  return await api.post(`/job/${id}/improvement`);
};

export const postJobExpire = async ({ id }: { id?: string }) => {
  return await api.post(`/job/${id}/expire`);
};

export const getJobNotes = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/notes`);
};

export const disableNote = async ({ id }: { id?: number }) => {
  return await api.post(`/jobnotes/${id}/disable`);
};

export const addJobNote = async ({ id, content }: { id?: string; content: string }) => {
  return await api.post(`/job/${id}/notes/add`, { content });
};

export const getJobUser = async ({ id }: { id?: string }) => {
  return await api.get(`/job/${id}/user`);
};
