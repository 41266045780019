import React, { MutableRefObject } from 'react';
import { Children } from '@epcbuilder/lib/utils';
import { Bottom, Page, Top } from './ReportLayout';

const Box = ({ className, children }: { className?: string; children: Children }) => {
  return (
    <div
      className={`${className} bg-light-dark flex h-[150px] w-[160px] flex-col items-center gap-3 rounded-[16px] p-4 shadow-[0_5px_5px_rgba(38,70,83,0.1)]`}
    >
      {children}
    </div>
  );
};

const ReportPage9 = ({ refs }: { refs: MutableRefObject<(HTMLDivElement | null)[]> }) => {
  return (
    <Page refs={refs} index={9}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Next Steps</h1>
      </Top>
      <div className="mt-[88px] flex flex-col gap-6">
        <div className="bg-primary/10 mx-20 flex flex-col gap-2 rounded-[16px] p-3">
          <p className="text-xs font-medium">There is no obligation to do anything.</p>
          <p className="text-xs font-medium">
            If you would like to install some, or all the recommended measures, you can either find your own
            installer(s) or use our in-house team.
          </p>
        </div>
        <p className="text-center text-xs font-bold uppercase">Why use our in-house team?</p>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-row gap-3">
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">
                Our experienced installers
                <br />
                do it all
              </p>
              <p className="text-center text-xs font-medium leading-none">
                They are experienced at installing a variety of measures which eliminates the need to find different
                installers for different measures.
              </p>
            </Box>
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">
                1 point
                <br />
                of contact
              </p>
              <p className="text-center text-xs font-medium leading-none">
                If we need to use a trusted partner we will project manage the full installation so you only ever have
                one point of contact.
              </p>
            </Box>
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">Full accreditation</p>
              <p className="text-center text-xs font-medium leading-none">
                Our in-house team are fully accredited by Trustmark, MCS and PAS.
              </p>
            </Box>
          </div>
          <div className="flex flex-row gap-3">
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">
                Manufacturers' warranties/ guarantees registered
              </p>
              <p className="text-center text-xs font-medium leading-none">
                We will register all associated manufacturer’s warranties/guarantees.
              </p>
            </Box>
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">
                2-year warranty on our workmanship
              </p>
              <p className="text-center text-xs font-medium leading-none">
                In addition to the manufacturer’s warranties, we provide a 2-year warranty on our workmanship.
              </p>
            </Box>
            <Box className="justify-center">
              <p className="text-center text-xs font-black uppercase leading-none">
                Building regulation compliance guaranteed
              </p>
            </Box>
          </div>
          <div className="flex flex-row gap-3">
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">Independent Advice</p>
              <p className="text-center text-xs font-medium leading-none">
                We're not tied to specific manufacturers so always recommend the product most suited to your property.
              </p>
            </Box>
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">
                All available grants/funding handled for you
              </p>
              <p className="text-center text-xs font-medium leading-none">
                We will search and apply for any grants/ funding you may be eligible for.
              </p>
            </Box>
            <Box>
              <p className="text-center text-xs font-black uppercase leading-none">Your updated EPC lodged</p>
              <p className="text-center text-xs font-medium leading-none">
                We will lodge an updated EPC once the measures are installed.
              </p>
            </Box>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-16">
          <div className="relative h-12 w-[132px]">
            <img src="/images/pdf-logo.png" alt="EPC Builder Logo" className="size-full object-contain" />
          </div>
          <div className="flex flex-col gap-2 text-center">
            <p className="text-xs font-bold leading-none">Contact us</p>
            <div className="flex flex-col">
              <p className="text-xs font-bold leading-none">0800 058 4140</p>
              <p className="text-xs font-bold leading-none">support@epcbuilder.com</p>
            </div>
          </div>
        </div>
      </div>
      <Bottom title="Next Steps" />
    </Page>
  );
};

export default ReportPage9;
