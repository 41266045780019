/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropRightLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { Button } from '@epcbuilder/lib/components';
import useImprovementQuickSelect from '@epcbuilder/lib/hooks/useImprovementQuickSelect';
import { ImprovementItem, JobImprovement, QuickSelectImprovementItem } from '@epcbuilder/lib/models/jobs';
import { KeyedMutator } from 'swr';
import { Improvement, JobReport } from '@/models/job';
import { postImprovementItem } from '@/network/improvements';

const ImprovementQuickSelect = ({
  improvement,
  refetchJobReport,
  setShowAddImprovementItem,
  setImprovementItem,
  selectType,
}: {
  improvement: Improvement;
  refetchJobReport: KeyedMutator<JobReport> | KeyedMutator<JobImprovement[]>;
  setShowAddImprovementItem: React.Dispatch<React.SetStateAction<boolean>>;
  setImprovementItem: React.Dispatch<React.SetStateAction<ImprovementItem | undefined>>;
  selectType: string;
}) => {
  const availableQuickSelectTypes = useImprovementQuickSelect();
  const [showItemQuickAddModal, setShowItemQuickAddModal] = useState<boolean>(false);

  const quickAddItem = async (quickItem: QuickSelectImprovementItem) => {
    const improvementItem: ImprovementItem = {
      itemText: quickItem.key,
      itemCost: quickItem.defaultCost,
    };

    if (quickItem.defaultCost > 0) {
      try {
        await postImprovementItem({ id: improvement.id, data: improvementItem });
        toast.success('Improvement item added');
      } catch {
        toast.error(`Unable to add ${quickItem.key}`);
      }
    } else {
      if (setImprovementItem !== undefined) setImprovementItem(improvementItem);
      setShowAddImprovementItem(true);
    }

    refetchJobReport();
    setShowItemQuickAddModal(false);
  };

  if (selectType == 'Button') {
    return (
      <Button onClick={() => setShowItemQuickAddModal(!showItemQuickAddModal)} style="primary">
        <div className="flex">
          <p>Item Quick Select</p>
          <div>
            {showItemQuickAddModal ? (
              <RiArrowDropRightLine
                className="relative"
                size={24}
                onClick={() => setShowItemQuickAddModal(!showItemQuickAddModal)}
              />
            ) : (
              <RiArrowDropDownLine
                className="relative"
                size={24}
                onClick={() => setShowItemQuickAddModal(!showItemQuickAddModal)}
              />
            )}

            {showItemQuickAddModal && (
              <>
                <div
                  onClick={() => setShowItemQuickAddModal(false)}
                  className="z-8 absolute left-[-50%] top-[-50%] h-screen w-screen"
                ></div>
                <div className={`dropdown absolute z-10 w-[220px] bg-white p-2 text-xl font-normal text-[#264653]`}>
                  <ul>
                    {availableQuickSelectTypes?.map((item) => (
                      <li onClick={() => quickAddItem(item)} key={item.key}>
                        {item.key}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </Button>
    );
  } else {
    return (
      <div>
        {showItemQuickAddModal ? (
          <RiArrowDropRightLine
            className="relative"
            size={24}
            onClick={() => setShowItemQuickAddModal(!showItemQuickAddModal)}
          />
        ) : (
          <RiArrowDropDownLine
            className="relative"
            size={24}
            onClick={() => setShowItemQuickAddModal(!showItemQuickAddModal)}
            id={`item-quick-add`}
          />
        )}

        {showItemQuickAddModal && (
          <>
            <div
              onClick={() => setShowItemQuickAddModal(false)}
              className="z-8 absolute left-[-50%] top-[-50%] h-screen w-screen"
            ></div>
            <div className={`dropdown absolute z-10 w-[220px] bg-white p-2 text-xl font-normal text-[#264653]`}>
              <ul>
                {availableQuickSelectTypes?.map((item) => (
                  <li onClick={() => quickAddItem(item)} key={item.key}>
                    {item.key}
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default ImprovementQuickSelect;
