import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal } from '@epcbuilder/lib/components';
import { JobInstallation } from '@epcbuilder/lib/models/jobs';
import { PropertyRating } from '@epcbuilder/lib/models/properties';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { Improvement } from '@/models/job';
import { postInstallationComplete } from '@/network/installations';

const JobInstallationCompletionModal = ({
  onClose,
  jobInstallation,
  refetchJobInstallation,
  selectedImprovement,
  propertyRating,
}: {
  onClose: () => void;
  jobInstallation: JobInstallation | undefined;
  refetchJobInstallation: () => void;
  selectedImprovement: Improvement | undefined;
  propertyRating: PropertyRating | undefined;
}) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      newEpcRating: selectedImprovement?.newEpc || '',
    },
  });

  const onSubmit = async (data: { newEpcRating: string }) => {
    try {
      await postInstallationComplete({ id: jobInstallation?.id, data: { newRating: data.newEpcRating } });
      toast.success('Installation marked as complete.');
      refetchJobInstallation();
      onClose();
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal id="job-pashub-modal" onClose={onClose}>
      <h1>Complete Installation</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <p id="current-epc">Current EPC Rating: {propertyRating?.currentRating || 'N/a'}</p>
        <Controller
          name="newEpcRating"
          control={control}
          render={({ field }) => <p id="new-epc">New EPC Rating: {field.value || 'N/a'}</p>}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default JobInstallationCompletionModal;
