import { JobInstallation } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getJobinstallation } from '@/network/jobs';

const useJobInstallation = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<JobInstallation>(
    id ? `job-installation-${id}` : null, // Use null if id is undefined
    async () => (id ? await getJobinstallation({ id }) : null)
  );

  return {
    jobInstallation: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobInstallation;
