import { User } from '@epcbuilder/lib/models/user';
import useSWR from 'swr';
import { getJobUser } from '@/network/jobs';

const useJobUser = ({ id }: { id: string | undefined }) => {
  const { data, error, mutate, isLoading } = useSWR<User>(`job-user-data-${id}`, async () => await getJobUser({ id }));

  return {
    jobUser: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobUser;
