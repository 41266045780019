import React from 'react';
import { AxiosResponse } from 'axios';
import { KeyedMutator } from 'swr';
import { JobReport } from '@/models/job';
import {
  deleteReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloor1Plan,
  putReportPropertyOverviewFloorPlan,
} from '@/network/report';
import { PropertyOverviewImage } from '../helpers';

export const FloorPlan = ({
  savingReport,
  title,
  image,
  callback,
  className,
  jobReport,
  refetchJobReport,
  onDelete,
  showDeleteButton,
}: {
  savingReport: boolean;
  title: string;
  image?: string;
  callback: ({ id, data }: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>;
  className: string;
  jobReport?: JobReport;
  refetchJobReport: KeyedMutator<JobReport>;
  onDelete?: ({ id }: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>;
  showDeleteButton?: boolean;
}) => (
  <div className="flex flex-1 flex-col gap-2">
    <p className="absolute-top ml-4 text-lg font-bold">{title}</p>
    <div className="bg-light-dark h-[260px] w-full rounded-[16px] p-2 shadow-[0_5px_5px_rgba(38,70,83,0.1)]">
      <PropertyOverviewImage
        savingReport={savingReport}
        title={title}
        image={image}
        callback={callback}
        jobReport={jobReport}
        refetchJobReport={refetchJobReport}
        className={className}
        onDelete={onDelete}
        showDeleteButton={showDeleteButton}
      />
    </div>
  </div>
);

const FloorPlansOverview = ({
  jobReport,
  savingReport,
  refetchJobReport,
  showExtraFloorPlans,
  viewMode,
}: {
  jobReport?: JobReport;
  savingReport: boolean;
  refetchJobReport: KeyedMutator<JobReport>;
  showExtraFloorPlans: boolean;
  viewMode: boolean;
}) => {
  const renderFloorPlan = (
    title: string,
    image: string | undefined,
    callback: (params: { id?: string; data: { value: string } }) => Promise<void | AxiosResponse<unknown, unknown>>,
    onDelete?: (params: { id?: string }) => Promise<void | AxiosResponse<unknown, unknown>>,
    showDeleteButton = false
  ) => (
    <FloorPlan
      title={title}
      image={image}
      callback={callback}
      jobReport={jobReport}
      refetchJobReport={refetchJobReport}
      savingReport={savingReport}
      className="bg-blue-light relative size-full overflow-hidden rounded-[12px]"
      onDelete={onDelete}
      showDeleteButton={showDeleteButton}
    />
  );

  if (showExtraFloorPlans) {
    return renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan);
  }

  if (
    (jobReport?.propertyOverview.floor1plan && !jobReport.propertyOverview.floor2plan) ||
    (viewMode && !showExtraFloorPlans)
  ) {
    return (
      <div className="flex flex-row gap-4">
        {renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan)}
        {renderFloorPlan(
          'First Floor',
          jobReport?.propertyOverview.floor1plan,
          putReportPropertyOverviewFloor1Plan,
          deleteReportPropertyOverviewFloor1Plan,
          !!jobReport?.propertyOverview.floor1plan
        )}
      </div>
    );
  }

  return renderFloorPlan('Ground Floor', jobReport?.propertyOverview.floorplan, putReportPropertyOverviewFloorPlan);
};

export default FloorPlansOverview;
