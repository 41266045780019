import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdClose, MdOutlineAlarm, MdOutlineFileDownloadDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Loading, TextInput } from '@epcbuilder/lib/components';
import { WorkingDay, WorkingDayForm } from '@epcbuilder/lib/models/assessors';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { DaysOfWeek } from '@epcbuilder/lib/utils/datetime';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const timeRegex = /^(?:([01]\d|2[0-3]):([0-5]\d)|)$/;

const workingDaySchema = yup.object().shape({
  startTime: yup
    .string()
    .matches(timeRegex, 'Invalid time format')
    .test('is-before-end', 'Start time must be before end time', function (value) {
      const { endTime } = this.parent;
      return !value || !endTime || value < endTime;
    }),
  endTime: yup
    .string()
    .matches(timeRegex, 'Invalid time format')
    .test('is-after-start', 'End time must be after start time', function (value) {
      const { startTime } = this.parent;
      return !value || !startTime || value > startTime;
    }),
});

const formSchema = yup.object().shape({
  workingDays: yup.array().of(workingDaySchema).required(),
});

const EditAvailabilityModal = ({
  onSubmit,
  defaultValues,
  onClose,
}: {
  defaultValues: WorkingDay[];
  onClose: () => void;
  onSubmit: (data: WorkingDayForm[]) => void;
}) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    register,
    setValue,
    clearErrors,
  } = useForm<{ workingDays: WorkingDayForm[] }>({
    defaultValues: { workingDays: defaultValues },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onSubmit',
  });

  const handleFormSubmit: SubmitHandler<{ workingDays: WorkingDayForm[] }> = async (data) => {
    try {
      const updatedData = data.workingDays.map((workingDay, index) => ({
        ...workingDay,
        dayName: DaysOfWeek[index],
      }));

      onSubmit(updatedData);
      toast.success('Availability updated successfully.', { toastId: 'edit-availability-success' });
      onClose();
    } catch (error: unknown) {
      if ((error as AxiosErrorData).errors) {
        const { errors } = error as AxiosErrorData;
        handleFormErrors(setError, errors);
        handleUnknownDetail(error);
      }
    }
  };

  const formatTime = (value: string) => {
    const cleaned = value.replace(/\D+/g, '');
    const truncated = cleaned.substring(0, 4);
    const match = truncated.match(/^(\d{0,2})(\d{0,2})$/);
    if (match) {
      const part1 = match[1];
      const part2 = match[2];
      if (part2) {
        return `${part1}:${part2}`.substring(0, 5);
      }
      return part1;
    }
    return value;
  };

  useEffect(() => {
    DaysOfWeek.forEach((day, index) => {
      const dayValue = defaultValues.find((d) => d.dayName === day);
      setValue(`workingDays.${index}.startTime`, dayValue ? dayValue.startTime.slice(0, 5) : '');
      setValue(`workingDays.${index}.endTime`, dayValue ? dayValue.endTime.slice(0, 5) : '');
    });
  }, [defaultValues, setValue]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <div id="availability-modal" className="fixed inset-0 z-50 flex items-center justify-center">
      <button
        type="button"
        className="bg-modal-blur fixed inset-0 flex items-center justify-center opacity-20"
        onClick={onClose}
      />
      <div className="bg-modal border-primary relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:max-h-[600px] sm:w-full sm:max-w-[400px] sm:rounded-xl">
        <button
          type="button"
          className="text-primary-lighter absolute right-0 top-0 cursor-pointer p-4"
          onClick={onClose}
        >
          <MdClose size={24} />
        </button>
        <p className="flex justify-center font-bold">EDIT AVAILABILITY</p>
        <form className="mt-4 flex flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="mt-4 flex justify-around">
            <div className="w-25"></div>
            <div className="ml-4 font-bold">Start Time</div>
            <div className="font-bold">Finish Time</div>
          </div>
          {DaysOfWeek.map((day, index) => (
            <div key={day} className="mt-2 flex flex-row gap-4">
              <button
                type="button"
                className="border-primary-lighter bg-blue text-dark flex h-12 w-[6rem] cursor-default items-center justify-center rounded-xl border-2 px-3 font-bold"
              >
                {day.substring(0, 3).toUpperCase()}
              </button>
              <div className="relative">
                <TextInput
                  {...register(`workingDays.${index}.startTime`)}
                  id={`${index}.startTime`}
                  className="border-blue bg-color-white hover:border-primary-lighter focus:border-primary-lighter placeholder:text-color flex h-12 w-full rounded-xl border-2 px-4 text-left shadow outline-none"
                  overrideBaseClassnames
                  formatValue={formatTime}
                  error={errors.workingDays?.[index]?.startTime?.message}
                  callbackOnChange={() => {
                    clearErrors();
                  }}
                />
                <MdOutlineAlarm size={20} className="text-color absolute right-4 top-6 -translate-y-1/2" />
              </div>
              <div className="relative gap-4">
                <TextInput
                  {...register(`workingDays.${index}.endTime`)}
                  id={`${index}.endTime`}
                  className="border-blue bg-color-white hover:border-primary-lighter focus:border-primary-lighter placeholder:text-color flex h-12 w-full rounded-xl border-2 px-4 text-left shadow outline-none"
                  overrideBaseClassnames
                  formatValue={formatTime}
                  error={errors.workingDays?.[index]?.endTime?.message}
                  callbackOnChange={() => {
                    clearErrors();
                  }}
                />
                <MdOutlineAlarm size={20} className="text-color absolute right-4 top-6 -translate-y-1/2" />
              </div>
            </div>
          ))}
          <div className="mt-4 flex justify-center">
            <button
              id="availability-submit"
              className="bg-blue font-header flex h-12 min-w-fit items-center justify-center rounded-xl px-4 text-base drop-shadow-[0px_4px_0px_#9CCEC8]"
            >
              <p className="text-dark flex flex-row gap-4">
                <MdOutlineFileDownloadDone size={20} /> Save Changes
              </p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAvailabilityModal;
