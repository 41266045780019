import React, { MutableRefObject } from 'react';
import { Bottom, Page, Top } from './ReportLayout';

const ReportPage10 = ({ refs }: { refs: MutableRefObject<(HTMLDivElement | null)[]> }) => {
  return (
    <Page refs={refs} index={10}>
      <Top>
        <h1 className="text-primary font-sans text-3xl font-black uppercase">Notes</h1>
      </Top>
      <div className="mx-16 flex h-full flex-col items-center justify-center gap-10">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold">Installation</p>
          <p className="text-xs font-medium leading-none">
            The Improveasy installations team are fully qualified to install a range of energy efficiency and renewable
            measures. There may be occasions when we choose to use a specialist subcontractor to install certain
            measures, e.g. Double Glazing. We will project manage the full installation, so you only ever have one point
            of contact.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold">Technical Surveys</p>
          <p className="text-xs font-medium leading-none">
            Measures and costs are all subject to a technical survey. A technical survey will take place prior to
            installation to check access, condition, etc. This is to ensure there are no issues installing the chosen
            measures.
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-xs font-bold">Ventilation</p>
          <p className="text-xs font-medium leading-none">
            To comply with regulations when installing any form of insulation, we may need to upgrade the ventilation.
            This may mean we need to retrofit window trickle vents, through wall background vents, upgrade extract fans
            to continuous DMEV's (Decentralised Mechanical Extract Ventilation) or install a PIV (Positive Input
            Ventilation) unit in the loft space.
          </p>
        </div>
        <div className="absolute bottom-32 mx-auto h-16 w-[215px]">
          <img src="/images/pdf-logo.png" alt="EPC Builder Logo" className="size-full object-contain" />
        </div>
      </div>
      <Bottom title="Notes" />
    </Page>
  );
};

export default ReportPage10;
