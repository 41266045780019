import { Assessor } from '@epcbuilder/lib/models/assessors';
import useSWR from 'swr';
import { getAllAssessors } from '@/network/assessors';

const useAssessors = () => {
  const { data, error, isLoading, mutate } = useSWR<Assessor[]>('assessors-data', async () => await getAllAssessors(), {
    keepPreviousData: true,
  });

  return {
    assessors: data,
    error,
    mutate,
    isLoading,
  };
};

export default useAssessors;
