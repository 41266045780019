import { Assessor } from '@epcbuilder/lib/models/assessors';
import useSWR from 'swr';
import { getAssessor } from '@/network/assessors';

const useAssessor = ({ id }: { id: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Assessor>(
    `assessor-data-${id}`,
    async () => await getAssessor({ id })
  );

  return {
    assessor: data,
    error,
    mutate,
    isLoading,
  };
};

export default useAssessor;
