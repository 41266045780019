import React, { useEffect, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Button } from '@epcbuilder/lib/components';
import { DeleteModal } from '@epcbuilder/lib/components/Modal';
import { InstallationStatus, Job } from '@epcbuilder/lib/models/jobs';
import { format } from 'date-fns';
import useInstallationBooking from '@/hooks/installations/useInstallationBooking';
import useJobImprovements from '@/hooks/jobs/useJobImprovements';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import usePropertyRating from '@/hooks/properties/usePropertyRating';
import { SurveyInstallationStatuses } from '@/models/job';
import { deleteInstallationBooking } from '@/network/installations';
import JobInstallationCompletionModal from './modals/JobInstallationCompletionModal';
import JobInstallationModal from './modals/JobInstallationModal';
import JobInstallationImprovements from './JobInstallationImprovements';
import JobInstallationStatus from './JobInstallationStatus';

const JobInstallationData = ({
  job,
  refetchJob,
  installationStatuses,
}: {
  job: Job | undefined;
  refetchJob: () => void;
  installationStatuses: SurveyInstallationStatuses[];
}) => {
  const {
    jobInstallation,
    error: jobInstallationError,
    mutate: refetchJobInstallation,
  } = useJobInstallation({ id: job?.id });
  const { installationBooking, mutate: refetchInstallationBooking } = useInstallationBooking({
    id: jobInstallation?.id,
  });
  const { jobImprovements } = useJobImprovements({ id: job?.id });
  const { propertyRating } = usePropertyRating({ id: job?.propertyId as string });
  const selectedImprovement = jobImprovements?.find(
    (improvement) => improvement.id === jobInstallation?.selectedImprovementId
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCompletionModal, setShowCompletionModal] = useState<boolean>(false);
  const [installationCompletedLock, setInstallationCompletedLock] = useState<boolean>(false);

  useEffect(() => {
    setInstallationCompletedLock(
      (jobInstallation?.installationStatusId &&
        jobInstallation.installationStatusId === InstallationStatus.INSTALLATION_COMPLETED) ??
        false
    );
  }, [jobInstallation]);

  const data = {
    Paid: String(installationBooking?.paid ?? false),
    'Actual Date/Time': installationBooking?.bookingDateTime
      ? format(new Date(installationBooking?.bookingDateTime), 'dd/MM/yyyy hh:mm aa')
      : '',
    Assessor: installationBooking?.assessor || '',
    Notes: installationBooking?.notes,
  };

  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        {jobInstallationError && (
          <div
            id="job-installation-error"
            className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]"
          >
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 max-w-[220px] rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">There currently is no installation for this job.</p>
            </div>
          </div>
        )}
        {installationCompletedLock && (
          <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center bg-[linear-gradient(-45deg,#c9e9e5_10%,transparent_10%,transparent_50%,#c9e9e5_50%,#c9e9e5_60%,transparent_60%,transparent)] bg-[length:8px_8px] dark:bg-[linear-gradient(-45deg,#0d695c_10%,transparent_10%,transparent_50%,#0d695c_50%,#0d695c_60%,transparent_60%,transparent)]">
            <div className="bg-blue/90 dark:bg-primary-dark/90 border-dark/30 dark:border-light/30 flex max-w-[220px] flex-col gap-2 rounded-[22px] border-2 p-4">
              <p className="text-center font-bold">This installation is currently completed and locked for editing.</p>
              <Button id="unlock-and-edit-installation-button" onClick={() => setInstallationCompletedLock(false)}>
                Unlock and Edit
              </Button>
            </div>
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h1>Installation details:</h1>
            <div className="flex flex-row items-center gap-2">
              <button
                type="button"
                id="delete-installation-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowDeleteModal(true)}
              >
                <MdDelete size={20} className="text-error" />
              </button>
              <button
                type="button"
                id="edit-installation-details-icon"
                className="flex cursor-pointer items-center justify-center p-2"
                onClick={() => setShowModal(true)}
              >
                <MdEdit size={20} className="text-primary" />
              </button>
            </div>
          </div>
          <JobInstallationStatus installationStatuses={installationStatuses} job={job} />
          <div className="mt-2 flex flex-col">
            <div className="flex flex-1 flex-col">
              {Object.entries(data).map(([key, value], i) => (
                <div key={i} className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row">
                  <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
                    {key}
                  </p>
                  <p
                    id={`access-${key.toLowerCase().replace(':', '').replaceAll(' ', '-')}`}
                    className="min-h-10 flex-1 self-stretch whitespace-normal break-all p-2 md:px-4"
                  >
                    {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <JobInstallationImprovements
          job={job}
          jobInstallation={jobInstallation}
          refetchJobInstallation={refetchJobInstallation}
        />
        <div className="mt-4">
          <Button
            id="complete-installation-button"
            onClick={() => setShowCompletionModal(true)}
            disabled={!jobInstallation?.selectedImprovementId}
          >
            Mark installation as complete
          </Button>
        </div>
      </div>
      {showModal && (
        <JobInstallationModal
          onClose={() => {
            refetchJob();
            refetchInstallationBooking();
            setShowModal(false);
          }}
          jobInstallation={jobInstallation}
          installationBooking={installationBooking}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          value="installation booking details"
          onClose={() => setShowDeleteModal(false)}
          callback={async () => {
            await deleteInstallationBooking({ id: jobInstallation?.id });
            await refetchJobInstallation();
            await refetchInstallationBooking();
          }}
        />
      )}
      {showCompletionModal && (
        <JobInstallationCompletionModal
          onClose={() => setShowCompletionModal(false)}
          jobInstallation={jobInstallation}
          selectedImprovement={selectedImprovement}
          propertyRating={propertyRating}
          refetchJobInstallation={refetchJobInstallation}
        />
      )}
    </>
  );
};

export default JobInstallationData;
