import React from 'react';

const EpcGraphBar = ({ rating, className }: { rating: string; className: string }) => {
  return (
    <div className={`flex h-4 flex-row items-center justify-end rounded-tr-[4px] px-1 ${className}`}>
      <p className="text-light-dark font-bold">{rating}</p>
    </div>
  );
};

export default EpcGraphBar;
