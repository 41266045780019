import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { MdLogout, MdPerson } from 'react-icons/md';
import { PiHouseSimpleBold } from 'react-icons/pi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HorizontalLine } from '@epcbuilder/lib/components';
import { AuthContext } from '@epcbuilder/lib/context/authContext';

enum MobileMenuItems {
  PROPERTIES = 'properties',
  ADMINS = 'admins',
  USERS = 'users',
  LOGOUT = 'logout',
}

const MenuItem = ({
  id,
  icon,
  text,
  active,
  setActive,
  href,
  onClick,
}: {
  id: MobileMenuItems;
  icon: JSX.Element;
  text: string;
  active: MobileMenuItems;
  setActive: Dispatch<SetStateAction<MobileMenuItems>>;
  href?: string;
  onClick?: () => void;
}) => {
  const baseClassNames =
    'rounded-full border-2 h-8 w-8 flex justify-center items-center cursor-pointer border-transparent';

  if (href) {
    return (
      <Link to={href} id={id} className="flex w-20 flex-col items-center gap-2" onClick={() => setActive(id)}>
        <div className={`${baseClassNames} ${active === id && 'border-primary bg-primary text-light shadow'}`}>
          {icon}
        </div>
        <p>{text}</p>
      </Link>
    );
  }

  if (onClick) {
    return (
      <button
        type="button"
        id={id}
        className="flex w-20 flex-col items-center gap-2"
        onClick={() => {
          setActive(id), onClick();
        }}
      >
        <div className={`${baseClassNames} ${active === id && 'border-primary bg-primary text-light shadow'}`}>
          {icon}
        </div>
        <p>{text}</p>
      </button>
    );
  }

  return (
    <div id={id} className="flex w-20 flex-col items-center gap-2">
      <div className={`${baseClassNames} ${active === id && 'border-primary bg-primary text-light shadow'}`}>
        {icon}
      </div>
      <p>{text}</p>
    </div>
  );
};

const MobileBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatchLogout } = useContext(AuthContext);
  const [active, setActive] = useState<MobileMenuItems>(location.pathname.split('/')[1] as MobileMenuItems);

  return (
    <div className="bg-color fixed bottom-0 z-10 w-full shadow sm:hidden">
      <HorizontalLine />
      <div className="flex h-24 flex-row items-center justify-around gap-2 py-4">
        <MenuItem
          id={MobileMenuItems.PROPERTIES}
          href="/properties"
          icon={<PiHouseSimpleBold size={20} />}
          text="Properties"
          active={active}
          setActive={setActive}
        />
        <MenuItem
          id={MobileMenuItems.ADMINS}
          href="/admins"
          icon={<MdPerson size={20} />}
          text="Admins"
          active={active}
          setActive={setActive}
        />
        <MenuItem
          id={MobileMenuItems.USERS}
          href="/users"
          icon={<MdPerson size={20} />}
          text="Users"
          active={active}
          setActive={setActive}
        />
        <MenuItem
          id={MobileMenuItems.LOGOUT}
          icon={<MdLogout size={20} />}
          text="Logout"
          active={active}
          setActive={setActive}
          onClick={() => {
            dispatchLogout();
            navigate('/');
          }}
        />
      </div>
    </div>
  );
};

export default MobileBar;
