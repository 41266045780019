import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Layout from '@/components/Layout';
import { Property } from '@/components/property';
import withAuth from '@/hoc/withAuth';

const Properties = () => {
  const params = useParams();

  const [id, setId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setId(params.id);
  }, [params]);

  return (
    <>
      <Helmet>
        <title>EPC Builder - Admin - Property</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Property">
        <>{id && <Property id={id} />}</>
      </Layout>
    </>
  );
};

export default withAuth(Properties);
