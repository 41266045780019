import { PropertyRating } from '@epcbuilder/lib/models/properties';
import useSWR from 'swr';
import { getPropertyRating } from '@/network/properties';

const usePropertyRating = ({ id }: { id: string }) => {
  const { data, error, mutate, isLoading } = useSWR<PropertyRating>(
    `property-rating-data-${id}`,
    async () => await getPropertyRating({ id })
  );

  return {
    propertyRating: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyRating;
