import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, Select } from '@epcbuilder/lib/components';
import { JobImprovement } from '@epcbuilder/lib/models/jobs';
import { epcRatingOptions } from '@epcbuilder/lib/models/properties';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { getOptionByValue } from '@epcbuilder/lib/utils/generic';
import { KeyedMutator } from 'swr';
import { putImprovementEpc } from '@/network/improvements';
import { putInstallationBookingImprovement } from '@/network/installations';

export const EpcModal = ({
  improvement,
  jobInstallationId,
  onClose,
  refetchJobImprovements,
  onEpcSelected,
  mode,
}: {
  improvement: JobImprovement;
  jobInstallationId?: string;
  onClose: () => void;
  refetchJobImprovements: KeyedMutator<JobImprovement[]>;
  onEpcSelected?: () => void;
  mode: 'add' | 'edit';
}) => {
  const defaultValues = {
    newEpc: '',
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<{ newEpc: string }>({ defaultValues });

  const onSubmit: SubmitHandler<{ newEpc: string }> = async (data) => {
    try {
      const optionLabel = getOptionByValue(epcRatingOptions, data.newEpc)?.label;
      if (!optionLabel) return;

      await putImprovementEpc({ id: improvement?.id, newEpc: optionLabel });

      if (mode === 'add') {
        await putInstallationBookingImprovement({ id: jobInstallationId, improvementId: improvement.id });
        if (onEpcSelected) {
          onEpcSelected();
        }
      }

      await refetchJobImprovements();
      toast.success(`${mode === 'add' ? 'Improvement selected' : 'EPC updated'} successfully.`);
      onClose();
    } catch (error: unknown) {
      const { detail } = error as AxiosErrorData;

      switch (detail) {
        case 'PasHub ID must be set to complete survey.':
          toast.error('PasHub ID must be entered before a survey can be marked as complete.');
          break;
        case 'Property Rating could not be found':
          toast.error('Property Rating details must be entered before a survey can be marked as complete.');
          break;
        default:
          handleUnknownDetail(error);
          break;
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <h1>{mode === 'add' ? 'New EPC rating' : 'Edit EPC rating'}</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <Select
          control={control}
          id="newEpc"
          name="newEpc"
          title={`select ${mode === 'add' ? 'new' : 'updated'} epc rating`}
          label="EPC Rating"
          placeholder=""
          options={epcRatingOptions}
        />
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
