import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import useJobUser from '@/hooks/jobs/useJobUser';

const JobUserData = ({ id }: { id: string | undefined }) => {
  const { jobUser, error } = useJobUser({ id });

  useEffect(() => {
    if (error) {
      const { detail } = error;
      if (detail === "User associated with property doesn't exist.") {
        toast.error('The user associated with this property does not exist.');
      }
    }
  }, [error]);

  return (
    <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
      <div className="flex flex-col gap-2">
        <h1>Owner:</h1>
        <p id="user-fullname">
          <span className="font-bold">Name: </span>
          {`${jobUser?.firstName} ${jobUser?.lastName}`}
        </p>
        <p id="user-email">
          <span className="font-bold">Email: </span> {`${jobUser?.email}`}
        </p>
        <p id="user-phone">
          <span className="font-bold">Phone: </span> {`${jobUser?.phone}`}
        </p>
        {jobUser?.secondaryPhone && (
          <p id="user-secondary-phone">
            <span className="font-bold">Secondary Phone: </span> {`${jobUser?.secondaryPhone}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default JobUserData;
