import { WorkingDay } from '@epcbuilder/lib/models/assessors';

const formatTime = (hour: number): string => {
  const period = hour >= 12 ? 'pm' : 'am';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${formattedHour}${period}`;
};

const convertTimeString = (timeString: string): string => {
  const [hourString] = timeString.split(':');
  const hour = parseInt(hourString, 10);
  return formatTime(hour);
};

const groupWorkingDays = (workingDays: WorkingDay[]): string => {
  const groupedDays: { [key: string]: string[] } = {};

  workingDays.forEach(({ dayName, startTime, endTime }) => {
    const key = `${startTime}-${endTime}`;
    if (!groupedDays[key]) {
      groupedDays[key] = [];
    }
    groupedDays[key].push(dayName);
  });

  const dayOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const formattedGroups = Object.entries(groupedDays).map(([timeRange, days]) => {
    const [startTime, endTime] = timeRange.split('-');
    const formattedStartTime = convertTimeString(startTime);
    const formattedEndTime = convertTimeString(endTime);

    days.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));

    let dayRange = days.join(', ');
    if (days.length > 1) {
      dayRange = `${days[0].slice(0, 3)} - ${days[days.length - 1].slice(0, 3)}`;
    }

    return `${dayRange}: ${formattedStartTime} to ${formattedEndTime}`;
  });

  return formattedGroups.join(', ');
};

export { convertTimeString, formatTime, groupWorkingDays };
