import React from 'react';

const AssessorsHeader = () => {
  return (
    <div className="px-8">
      <div className="border-bg-color-inverse flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-4">
        <div className="flex size-full min-h-12 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2">
            <div className={`mr-4 min-w-fit flex-1`}>
              <p className="flex h-full w-40 flex-row items-center gap-4 font-bold">NAME</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 md:flex`}>
              <p className="flex h-full w-48 flex-row items-center gap-4 font-bold xl:w-64">EMAIL</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
              <p className="flex h-full w-24 flex-row items-center gap-4 font-bold">AVAILABILITY</p>
            </div>
            <div className={`mr-4 hidden min-w-fit flex-1 lg:flex`}>
              <p className="w-34 flex h-full flex-row items-center gap-4 font-bold">LAST UPDATED</p>
            </div>
          </div>
        </div>
        <div className="w-44"></div>
      </div>
    </div>
  );
};

export default AssessorsHeader;
