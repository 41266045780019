import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { Job, JobStatusStrings } from '@epcbuilder/lib/models/jobs';
import { capitalize } from '@epcbuilder/lib/utils';
import { format } from 'date-fns';
import JobDetailsModal from './modals/JobDetailsModal';

const JobDetailsData = ({ job, refetchJob }: { job: Job | undefined; refetchJob: () => void }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const data = {
    'Status:': job?.jobStatusID ? capitalize(JobStatusStrings[job?.jobStatusID]) : '',
    'PASHUB Job ID:': job?.pasHubJobId,
    'Created On:': job?.createdDate ? format(new Date(job?.createdDate), 'dd/MM/yyyy') : '',
  };

  return (
    <>
      <div className="border-neutral-dark hover:border-primary relative gap-4 rounded-xl border-2 p-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h1>Job details:</h1>
            <button
              type="button"
              id="edit-pashubid-button"
              className="flex cursor-pointer items-center justify-center p-2"
              onClick={() => setShowModal(true)}
            >
              <MdEdit size={20} className="text-primary" />
            </button>
          </div>
          <div className="mt-2 flex flex-col">
            <div className="flex flex-1 flex-col">
              {Object.entries(data).map(([key, value], i) => (
                <div key={i} className="border-neutral-dark mt-[-2px] flex flex-col items-center border-2 lg:flex-row">
                  <p className="border-neutral-dark bg-primary/20 flex-1 self-stretch whitespace-normal break-all border-b-2 p-2 md:px-4 lg:border-b-0 lg:border-r-2 lg:bg-transparent">
                    {key}
                  </p>
                  <p
                    id={`details-${key.toLowerCase().replace(':', '').replaceAll(' ', '-')}`}
                    className="min-h-10 flex-1 self-stretch whitespace-normal break-all p-2 md:px-4"
                  >
                    {value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showModal && job && (
        <JobDetailsModal
          onClose={() => {
            refetchJob();
            setShowModal(false);
          }}
          job={job}
        />
      )}
    </>
  );
};

export default JobDetailsData;
