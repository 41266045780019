import { Absence } from '@epcbuilder/lib/models/assessors';
import useSWR from 'swr';
import { getAbsence } from '@/network/assessors';

const useAbsence = ({ id, start, end }: { id: string; start: Date; end: Date }) => {
  const { data, error, mutate, isLoading } = useSWR<Absence[]>(
    `absence-data-${id}`,
    async () => await getAbsence({ id, start, end })
  );

  return {
    absence: data,
    error,
    mutate,
    isLoading,
  };
};

export default useAbsence;
