import React from 'react';
import { Control, FieldValues, Path } from 'react-hook-form';
import { Select } from '@epcbuilder/lib/components';

const assessorStatusOptions = [
  { value: '', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const FilterAssessor = <T extends FieldValues>({ control }: { control: Control<T> }) => {
  return (
    <div id="assessors-filters" className="flex flex-1 flex-col flex-wrap gap-4 sm:flex-row sm:items-center">
      <div className="h-12 w-52">
        <Select
          className="border-primary-lighter bg-color-white hover:border-primary-lighter focus:border-primary-lighter placeholder:text-color flex h-12 flex-1 flex-row items-center justify-center rounded-[24px] border-2 px-4 shadow outline-none"
          control={control}
          id="status"
          name={'status' as Path<T>}
          title="Filter by assessor status"
          options={assessorStatusOptions}
        />
      </div>
    </div>
  );
};
export default FilterAssessor;
