import React from 'react';
import { MdOutlinePersonSearch } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button } from '@epcbuilder/lib/components';
import { Assessor } from '@epcbuilder/lib/models/assessors';
import { format } from 'date-fns';
import { groupWorkingDays } from '@/utils/timeUtils';

const AssessorsRow = ({ assessor }: { assessor: Assessor; mutate: () => void }) => {
  const navigate = useNavigate();
  const groupWorkingString = groupWorkingDays(assessor.workingDays);

  return (
    <>
      <div
        id={assessor.id}
        className={`border-neutral flex min-h-12 w-full flex-row items-center justify-between border-b-2 px-4 ${
          assessor.active === false ? 'bg-blue-light dark:bg-dark-dark' : ''
        } relative`}
      >
        {assessor.active === false && <div className="inactive-overlay"></div>}
        <div className="flex size-full min-h-20 flex-row gap-2">
          <div className="flex flex-1 flex-row gap-2 p-2">
            <div className="mr-4 min-w-fit flex-1">
              <p id={`${assessor.id}-fullName`} className="flex h-full w-40 flex-row items-center gap-4">
                {assessor.firstName} {assessor.lastName}
              </p>
            </div>
            <div className="mr-4 hidden min-w-fit flex-1 items-center md:flex">
              <p id={`${assessor.id}-email`} className="w-48 truncate xl:w-64">
                {assessor.email}
              </p>
            </div>
            <div className="mr-4 hidden min-w-fit flex-1 lg:flex">
              <p id={`${assessor.id}-working-hours`} className="flex h-full w-48 items-center gap-4 break-all xl:w-60">
                {groupWorkingString.split(', ').map((workingDays, index) => (
                  <React.Fragment key={index}>
                    {workingDays}
                    {index < groupWorkingString.split(', ').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            </div>
            <div className="mr-4 hidden min-w-fit flex-1 lg:flex">
              <p id={`${assessor.id}-dateAdded`} className="flex h-full w-24 items-center gap-4">
                {format(new Date(assessor.dateAdded), 'dd/MM/yyyy')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-40 min-w-fit flex-row items-center justify-end">
          <Button
            id={`${assessor.id}-view-button`}
            className="hover:bg-blue hover:border-primary-lighter flex w-full min-w-fit flex-1 flex-row bg-white"
            style="secondary"
            onClick={() => navigate(`/assessor/${assessor.id}`)}
          >
            <p className="text-color flex flex-row">
              <MdOutlinePersonSearch size={20} />
              View
            </p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default AssessorsRow;
