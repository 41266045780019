import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Layout from '@/components/Layout';
import { PropertyReport } from '@/components/property-report';
import withAuth from '@/hoc/withAuth';

const Report = () => {
  const params = useParams();

  const [id, setId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setId(params.id);
  }, [params]);

  return (
    <>
      <Helmet>
        <title>EPC Builder - Admin - Report</title>
        <meta name="description" content="" />
      </Helmet>
      <Layout title="Property Report">
        <>{id && <PropertyReport id={id} />}</>
      </Layout>
    </>
  );
};

export default withAuth(Report);
