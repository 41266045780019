import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePicker = <T extends FieldValues>({
  id,
  control,
  name,
  label,
  inline = false,
  showTimeSelect = false,
  error,
  className = '',
}: {
  id: string;
  control: Control<T>;
  name: Path<T>;
  label?: string;
  inline?: boolean;
  showTimeSelect?: boolean;
  error?: string;
  className?: string;
}) => {
  const baseClassNames =
    'h-10 w-full flex items-center text-base text-color rounded-xl px-4 border-2 bg-color cursor-pointer pr-8';

  type CustomInputProps = {
    value?: string;
    onClick?: () => void;
  };

  const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(({ value, onClick }: CustomInputProps, ref) => {
    const combinedClassNames = `${baseClassNames} ${className} border-neutral`;
    return (
      <button type="button" className={combinedClassNames} onClick={onClick} ref={ref}>
        {value}
      </button>
    );
  });

  CustomInput.displayName = 'DatePickerInput';

  return (
    <div id={id} className={`relative flex w-full ${inline ? 'flex-row items-center' : 'flex-col'}`}>
      {label && <div className="text-base font-bold">{label}</div>}
      <div className="relative flex w-full flex-col">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <ReactDatePicker
              todayButton="Today"
              showTimeSelect={showTimeSelect}
              selected={value}
              onChange={onChange}
              customInput={<CustomInput />}
              dateFormat={showTimeSelect ? 'dd/MM/yyyy hh:mm aa' : 'dd/MM/yyyy'}
            />
          )}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
