import { Job } from '@epcbuilder/lib/models/jobs';
import useSWR from 'swr';
import { getPropertyActiveJob } from '@/network/properties';

const usePropertyActiveJob = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<Job>(
    id ? `property-active-job-${id}` : null, // Use null if id is undefined
    async () => (id ? await getPropertyActiveJob({ id }) : null)
  );

  return {
    job: data,
    error,
    mutate,
    isLoading,
  };
};

export default usePropertyActiveJob;
