import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { Property } from '@epcbuilder/lib/models/properties';
import PropertyRow from './PropertyRow';

const PropertiesTable = ({
  count,
  isLoading,
  properties,
}: {
  count: number | undefined;
  isLoading: boolean;
  properties: Property[] | undefined;
}) => {
  if (isLoading || !properties) {
    return <Loading id="properties-loading" />;
  }

  if (count === 0) {
    return (
      <p id="properties-count-empty" className="my-8 flex items-center justify-center">
        There are no properties that match these filters
      </p>
    );
  }

  return (
    <div id="properties-table">
      {properties.map((property) => (
        <PropertyRow key={property.id} property={property} />
      ))}
    </div>
  );
};

export default PropertiesTable;
