import React from 'react';
import { Button } from '@epcbuilder/lib/components';
import Modal from '@epcbuilder/lib/components/Modal';

export const RestoreModal = ({
  value,
  onClose,
  callback,
}: {
  value: string | undefined;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  return (
    <Modal id="restore-modal" onClose={onClose}>
      <h1>Are you sure?</h1>
      <p className="mt-4">
        You are about to restore user <span className="font-bold">{value}</span>
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="restore-confirm"
            style="primary"
            onClick={async () => {
              await callback();
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RestoreModal;
