import React from 'react';
import { Note } from '@epcbuilder/lib/models/jobs';
import JobNote from './JobNote';

const JobNotesData = ({ notes, refetch }: { notes: Note[] | undefined; refetch: () => void }) => {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        {!notes && <h4>No notes on this job</h4>}
        {notes && (
          <div id="notes-container" className="w-full">
            {notes.map((note) => (
              <JobNote refetchJobNotes={refetch} key={note.id} note={note} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default JobNotesData;
