import React, { MutableRefObject } from 'react';
import { Children } from '@epcbuilder/lib/utils';

export const Page = ({
  children,
  refs,
  index,
  transparent,
}: {
  children: Children;
  refs: MutableRefObject<(HTMLDivElement | null)[]>;
  index: number;
  transparent?: boolean;
}) => {
  return (
    <div className="flex w-[1024px] min-w-full items-center justify-center">
      <div className="border-neutral border-2 shadow-lg">
        <div
          className={`${
            transparent ? 'from-blue-light/80' : 'from-blue-light'
          } text-dark relative flex h-[842px] w-[595px] flex-col gap-8 bg-gradient-to-b to-white`}
          ref={(el) => (refs.current[index] = el)}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const Top = ({ dark = false, children }: { dark?: boolean; children?: Children }) => {
  return (
    <div className="pointer-events-none absolute left-0 top-0 z-10 flex w-full flex-row items-start overflow-hidden pb-4">
      <div className="flex flex-col">
        <div
          className={`${dark ? 'bg-primary' : 'bg-light-dark'} h-20 w-12 shadow-[4px_8px_8px_0px_rgba(38,70,83,0.1)]`}
        />
        <div
          className={`${dark ? 'bg-primary' : 'bg-light-dark'} h-4 w-8 shadow-[4px_8px_8px_0px_rgba(38,70,83,0.1)]`}
        />
        <div
          className={`${
            dark ? 'bg-primary' : 'bg-light-dark'
          } -ml-2 h-4 w-6 shadow-[4px_8px_8px_0px_rgba(38,70,83,0.1)]`}
        />
      </div>
      <div
        className={`${
          dark ? 'from-primary via-dark to-dark bg-gradient-to-r' : 'bg-light-dark'
        } z-10 flex h-16 flex-1 shadow-[8px_8px_8px_0px_rgba(38,70,83,0.1)]`}
      />
      <div className="absolute z-10 flex h-16 w-full items-center justify-center">{children}</div>
    </div>
  );
};

export const Bottom = ({ title }: { title?: string }) => {
  return (
    <div className="pointer-events-none absolute bottom-0 right-0 z-10 flex w-full flex-row overflow-hidden pt-4">
      <div className="mb-8 flex flex-1 flex-row items-end">
        <div className={`${!title && 'pb-[13px]'} flex flex-1 flex-row items-center`}>
          {title && (
            <div className="pl-8 pr-2">
              <p className="text-dark font-sans text-lg font-medium">{title}</p>
            </div>
          )}
          <div className="from-secondary-dark via-secondary to-secondary-light h-0.5 flex-1 bg-gradient-to-r" />
          <div className="from-secondary-light via-primary to-primary-dark h-0.5 flex-1 bg-gradient-to-r" />
        </div>
        <div className="mb-[13px] flex flex-row items-end">
          <div className="bg-primary-dark h-4 w-0.5" />
          <div className="bg-primary-dark -ml-0.5 mb-4 h-0.5 w-4" />
          <div className="bg-primary-dark mb-4 h-4 w-0.5" />
          <div className="bg-primary-dark -ml-0.5 mb-8 h-0.5 w-4" />
          <div className="bg-primary-dark mb-8 h-4 w-0.5" />
          <div className="bg-primary-dark -ml-0.5 mb-12 h-0.5 w-4" />
        </div>
      </div>
    </div>
  );
};

export const TableContents = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="relative z-10 w-1/2">
        <div className="absolute top-1/2 -z-10 flex w-full -translate-y-1/2 flex-row opacity-30">
          <div className="from-secondary-dark via-secondary to-secondary-light h-4 flex-1 bg-gradient-to-r" />
          <div className="from-secondary-light via-primary to-primary-dark h-4 flex-1 bg-gradient-to-r" />
        </div>
      </div>
      <p className="text-lg">{title}</p>
    </div>
  );
};
