import { formatISO } from 'date-fns';
import useSWR from 'swr';
import { BookingAvailability } from '@/models/job';
import { getSurveyBookingAvailability } from '@/network/surveys';

const useSurveyBookingAvailableSlots = ({ start, end }: { start: Date; end: Date }) => {
  const { data, error, mutate, isLoading } = useSWR<BookingAvailability[]>(
    `survey-booking-unavailability-slot-${formatISO(start)}-${formatISO(end)}`,
    async () => await getSurveyBookingAvailability({ start, end })
  );

  return {
    availableSlots: data,
    error,
    mutate,
    isLoading,
  };
};

export default useSurveyBookingAvailableSlots;
