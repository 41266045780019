import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal } from '@epcbuilder/lib/components';
import { JobSurvey } from '@epcbuilder/lib/models/jobs';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { KeyedMutator } from 'swr';
import { SurveyBooking } from '@/models/job';
import { clearRequestedBooking } from '@/network/surveys';

const ClearRequestModal = ({
  onClose,
  jobSurvey,
  refetchSurveyBooking,
  refetchJobSurvey,
}: {
  onClose: () => void;
  jobSurvey: JobSurvey | undefined;
  refetchSurveyBooking: KeyedMutator<SurveyBooking>;
  refetchJobSurvey: KeyedMutator<JobSurvey>;
}) => {
  const defaultValues = {
    surveyId: jobSurvey?.id,
  };

  const {
    getValues,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ surveyId: string }>({ defaultValues });

  const onSubmit: SubmitHandler<{ surveyId: string }> = async () => {
    try {
      await clearRequestedBooking({ id: getValues('surveyId') });
      toast.success('Booking request cleared', { toastId: 'survey-clear-request-success' });
      await refetchSurveyBooking();
      await refetchJobSurvey();
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<{ surveyId: string }>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal id="survey-clear-request-modal" onClose={onClose}>
      <h1>Clear booking request</h1>
      <p>This removes the requested date, slot, and set the survey status to pending</p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ClearRequestModal;
