import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Loading, Modal, TextInput } from '@epcbuilder/lib/components';
import { Property, PropertyAccessDetails } from '@epcbuilder/lib/models/properties';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { putPropertyAccess } from '@/network/properties';

const PropertyAccessModal = ({ onClose, property }: { onClose: () => void; property: Property }) => {
  const defaultValues = {
    firstName: property.accessDetails.firstName,
    lastName: property.accessDetails.lastName,
    email: property.accessDetails.email,
    phone: property.accessDetails.phone,
    notes: property.accessDetails.notes,
  };

  const {
    register,
    getValues,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PropertyAccessDetails>({ defaultValues });

  const onSubmit: SubmitHandler<PropertyAccessDetails> = async () => {
    try {
      await putPropertyAccess({ id: property.id, data: getValues() });
      toast.success('Property Address Updated', { toastId: 'access-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<PropertyAccessDetails>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="access-modal" onClose={onClose}>
      <h1>Edit Access</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-4">
        <TextInput id="firstName" {...register('firstName')} label="First Name" error={errors.firstName?.message} />
        <TextInput id="lastName" {...register('lastName')} label="Last Name" error={errors.lastName?.message} />
        <TextInput id="email" {...register('email')} label="Email" error={errors.email?.message} />
        <TextInput id="phone" {...register('phone')} label="Phone" error={errors.phone?.message} />
        <TextInput id="notes" {...register('notes')} label="Notes" error={errors.notes?.message} />

        <div className="flex flex-row items-center justify-between gap-4">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="access-submit" loading={isSubmitting} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PropertyAccessModal;
